import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import ReactToPrint from 'react-to-print'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import ReactWhatsapp from 'react-whatsapp';
import whatsapp from '../../assets/images/whatsapp.png'


import {
  Template,
  Stepper,
  ActionButton,
  Loader,
  TabPanel,
  GenerateBill,
  GenerateHtml,
} from '../../components'


import {
  AntTabs,
} from '../../configCSS'


class Print extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isTripStepValidated: PropTypes.bool.isRequired,
    locationList: PropTypes.array,
    roomList: PropTypes.array,
    tripDetails: PropTypes.array,
    tripContent: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    LOADER: PropTypes.func.isRequired,
    tripPension: PropTypes.array,
    tripBilling: PropTypes.object,
    tripDevisFinal: PropTypes.object,
    putTripDevisFinal: PropTypes.object,
    userAccount: PropTypes.object,
    companyContent: PropTypes.object.isRequired,
    informationsContent: PropTypes.object,
    ACTIVE_STEP: PropTypes.func.isRequired,
    GET_TRIP_ALLDAYS: PropTypes.func.isRequired,
    GET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPTION: PropTypes.func.isRequired,
    GET_TRIP_PENSION: PropTypes.func.isRequired,
    RESET_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoading: true,
    locationList: [],
    roomList: [],
    tripDetails: [],
    tripContent: [],
    tripAllDays: [],
    putTripDevisFinal: {},
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripPension: [],
    tripBilling: {},
    tripDevisFinal: {},
    userAccount: {},
    informationsContent: {},
  }

  constructor(props) {
    super(props);
    this.onChangeTab = this.onChangeTab.bind(this)
    this.getAllTripInformationLoad = this.getAllTripInformationLoad.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.tabProps = this.tabProps.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      isLoading: false,
      isReadyToPrint: true,
      tripContent: [],
      validMsg: null,
      tripAllDays: [],
      tripAccomodation: [],
      tripPension: [],
      tripDetails: [],
      tripBilling: {},
      errorMsg: null,
      keyTab: 0,
    }
  }

  componentDidMount() {
    const {
      ACTIVE_STEP,
      LOADER,
      history,
      isTripStepValidated,
      tripContent,
      tripAllDays,
      tripAccomodation,
      tripPension,
      tripDetails,
      tripBilling,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // if refresh page go back to main page 'trip'
    if (!isTripStepValidated) history.push('trip')

    if (!_.isEmpty(tripContent)) {
      LOADER(true)
      this.getAllTripInformationLoad(tripContent)
    }

    ACTIVE_STEP('print')
    this.setState({
      tripContent,
      tripAllDays,
      tripAccomodation,
      tripPension,
      tripDetails,
      tripBilling,
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      token,
      isReadyToPrint,
      tripContent: tripContentOld,
      tripAllDays: tripAllDaysOld,
      tripAccomodation: tripAccomodationOld,
      tripPension: tripPensionOld,
      tripDetails: tripDetailsOld,
      tripBilling: tripBillingOld,
    } = this.state
    const {
      tripContent,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripDevisFinal,
      tripPension,
      tripDetails,
      putTripDevisFinal,
      tripBilling,
      GET_TRIP_ALLDAYS,
      RESET_TRIP_DEVIS_FINAL,
      GET_TRIP_ACCOMODATION,
      GET_TRIP_PENSION,
      GET_TRIP_DEVIS_FINAL,
      LOADER,
    } = nextProps
    // After updating content devis relaod the tripDevisFinal
    if (putTripDevisFinal && putTripDevisFinal.success) {
      RESET_TRIP_DEVIS_FINAL()
      GET_TRIP_DEVIS_FINAL(token, tripDetails[0].id_devis)
      this.setState({
        validMsg: 'Quote was successfully recorded !',
      })
      this.resetValidMsg(2000)
    }
    // check if all informations have been received
    if (!_.isEqual(tripAllDays, tripAllDaysOld)) this.setState({ tripAllDays })
    if (!_.isEqual(tripAccomodation, tripAccomodationOld)) this.setState({ tripAccomodation })
    if (!_.isEqual(tripPension, tripPensionOld)) this.setState({ tripPension })
    if (!_.isEqual(tripBilling, tripBillingOld)) this.setState({ tripBilling })
    if (!isReadyToPrint && !_.isEmpty(tripAllDays) && !_.isEmpty(tripAccomodation) && !_.isEmpty(tripPension)) {
      this.setState({
        isLoading: false,
        isReadyToPrint: true,
      })
    }
    // Get last info from tripContent
    if (!_.isEmpty(tripContent) && !_.isEqual(tripContent, tripContentOld)) {
      this.setState({
        isLoading: true,
        isReadyToPrint: false,
        tripContent,
        tripAllDays: [],
        tripAccomodation: [],
        tripPension: [],
      })
      GET_TRIP_ALLDAYS(tripContent[0].journee, tripContent[0].id_circuit)
      GET_TRIP_ACCOMODATION(tripContent[0].hebergement)
      GET_TRIP_PENSION(tripContent[0].pension)
    }
    // Get last info from tripDetails
    if (!_.isEmpty(tripDetails) && !_.isEqual(tripDetails, tripDetailsOld)) {
      this.setState({
        tripDetails,
      })
    }
    // check if all informations have been received adn valid step
    if (tripAccomodation && tripAccomodationOpt1 && tripAccomodationOpt2 && tripDevisFinal) {
      LOADER(false)
    }
  }

  componentWillUnmount() {
    const { ACTIVE_STEP } = this.props
    ACTIVE_STEP('')
  }

  async getAllTripInformationLoad(content) {
    const {
      token,
    } = this.state
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripDevisFinal,
      tripDetails,
      GET_TRIP_DEVIS_FINAL,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripAccomodation)) await GET_TRIP_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripAccomodationOpt1)) await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripAccomodationOpt2)) await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripDevisFinal)) GET_TRIP_DEVIS_FINAL(token, tripDetails[0].id_devis)
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }


  handleClick = () => {
    window.print(document.body.innerHTML)
  }


  // TABS
  onChangeTab = (event, keyTab) => {
    this.setState({
      keyTab,
    })
  }

  tabProps = index => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  resetValidMsg = (t = 3000) => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, t)
  }


  render() {
    const {
      isLoading,
      errorMsg,
      keyTab,
      validMsg,
    } = this.state

    const {
      tripDetails,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
    } = this.props
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <Stepper />
            <div className="dividerStepper" />
            {validMsg ? <div className="validMsg">{validMsg}</div> : null}
            {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
            {isLoading ? <Loader /> : null}
            <br />
            <div className="management">
              <AppBar position="static" className="tab-bar">
                <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
                  <Tab label="Devis" {...this.tabProps(0)} />
                  <Tab label="Invoice" {...this.tabProps(1)} />
                </AntTabs>
              </AppBar>
              <TabPanel className="tabulation" value={keyTab} index={0}>
                <Grid className="tabulation2" container spacing={2}>
                  {tripDetails && tripDetails[0].telephone
                  && (
                  <Grid item xs={4}>
                    <ReactWhatsapp className="buttonWhat" number={tripDetails[0].telephone} message="Bonjour">
                      <img src={whatsapp} />
                    </ReactWhatsapp>

                  </Grid>
                  )}
                </Grid>

                { tripAccomodation && tripAccomodationOpt1 && tripAccomodationOpt2
                && <GenerateHtml />
                }

              </TabPanel>
              <TabPanel value={keyTab} index={1}>
                <Grid container spacing={2}>

                  <Grid item xs={4}>
                    <ReactToPrint
                      content={() => this.componentRef}
          // eslint-disable-next-line react/button-has-type
                      trigger={() => <ActionButton label="export PDF" />}
                    />

                  </Grid>
                </Grid>
                <GenerateBill ref={response => (this.componentRef = response)} />
              </TabPanel>
            </div>

          </div>
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  isTripStepValidated: state.api.isTripStepValidated,
  locationList: state.api.locationList,
  roomList: state.api.roomList,
  tripDetails: state.api.tripDetails,
  tripContent: state.api.tripContent,
  tripAllDays: state.api.tripAllDays,
  tripAccomodation: state.api.tripAccomodation,
  tripPension: state.api.tripPension,
  tripRoom: state.api.tripRoom,
  putTripDevisFinal: state.api.putTripDevisFinal,
  tripBilling: state.billingReducer.tripBilling,
  userAccount: state.userAccountReducer.userAccount,
  companyContent: state.companyReducer.companyContent,
  informationsContent: state.informationsReducer.informationsContent,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  GET_TRIP_ALLDAYS: (tripDaysList, idCircuit) => dispatch({
    type: 'GET_TRIP_ALLDAYS',
    tripDaysList,
    idCircuit,
  }),
  GET_TRIP_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPTION: tripAccomodationOptList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPTION',
    tripAccomodationOptList,
  }),
  GET_TRIP_PENSION: tripPensionList => dispatch({
    type: 'GET_TRIP_PENSION',
    tripPensionList,
  }),
  GET_TRIP_ROOM: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT1: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT1',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT2: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT2',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_DEVIS_FINAL: (token, idDevis) => dispatch({
    type: 'GET_TRIP_DEVIS_FINAL',
    token,
    idDevis,
  }),
  RESET_TRIP_DEVIS_FINAL: () => dispatch({
    type: 'RESET_TRIP_DEVIS_FINAL',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Print))
