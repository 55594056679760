import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash'

import {
  Input,
  ActionButton,
} from '..'

class ModalActivity extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onResponseNo: PropTypes.func.isRequired,
    onResponseYes: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  }


  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onResponseNo = this.onResponseNo.bind(this)
    this.onResponseYes = this.onResponseYes.bind(this)
    this.state = {
      activityContent: '',
    };
  }


  // ********************
  // Modal
  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  // ******************************
  // Action on input
  handleChange = field => (event) => {
    const { activityContent } = this.state
    this.setState({
      activityContent: {
        ...activityContent,
        [field]: event.target.value,
      },
    })
  }


  // **********************
  // Modal response
  onResponseNo = () => {
    const { onResponseNo } = this.props
    onResponseNo()
  }

  onResponseYes = () => {
    const { activityContent } = this.state
    const { onResponseYes } = this.props
    onResponseYes(activityContent)
  }

  // **************************
  // Render
  render() {
    const {
      isModalOpen,
    } = this.props
    const {
      activityContent,
    } = this.state

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isModalOpen}
        onClose={this.handleClose}
      >
        <div className="modal">
          <h2 id="simple-modal-title">Activity</h2>
          <Input
            id="activity"
            label="Activity name"
            value={!_.isEmpty(activityContent) && activityContent.activity ? activityContent.activity : ''}
            onChange={this.handleChange('activity')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
          <Input
            id="activity_theme"
            label="activity theme *"
            select
            value={!_.isEmpty(activityContent) && activityContent.activity_theme ? activityContent.activity_theme : ''}
            onChange={this.handleChange('activity_theme')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          >
            <MenuItem value="Food">Food</MenuItem>
            <MenuItem value="Transportation">Transportation</MenuItem>
            <MenuItem value="Activity">Activity</MenuItem>
            <MenuItem value="Extra">Service guide</MenuItem>
            <MenuItem value="Flight">Flight</MenuItem>
            <MenuItem value="Extra">Extra</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Input>
          <Input
            id="currency"
            label="currency *"
            select
            value={!_.isEmpty(activityContent) && activityContent.currency ? activityContent.currency : ''}
            onChange={this.handleChange('currency')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          >
            <MenuItem value="$">$</MenuItem>
            <MenuItem value="€">€</MenuItem>
            <MenuItem value="VND">VND</MenuItem>
            <MenuItem value="THB">THB</MenuItem>
            <MenuItem value="CNY">CNY</MenuItem>
          </Input>
          <Input
            id="activity_type"
            label="activity type *"
            select
            margin="normal"
            variant="outlined"
            value={!_.isEmpty(activityContent) && activityContent.activity_type ? activityContent.activity_type : ''}
            className="largeWidth"
            onChange={this.handleChange('activity_type')}
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="group">Group</MenuItem>
          </Input>
          {activityContent && activityContent.activity_type === 'group' && (
          <div>
            <Input
              label="Adult price"
              name="price_adultpeakseason"
              type="number"
              margin="normal"
              variant="outlined"
              value={!_.isEmpty(activityContent) && activityContent.price_adultpeakseason ? activityContent.price_adultpeakseason : ''}
              onChange={this.handleChange('price_adultpeakseason')}
            />
          </div>
          )}
          {activityContent && activityContent.activity_type === 'individual' && (
          <div>
            <Input
              label="Adult A price"
              name="price_seniorpeakseason"
              type="number"
              margin="normal"
              variant="outlined"
              value={!_.isEmpty(activityContent) && activityContent.price_seniorpeakseason ? activityContent.price_seniorpeakseason : ''}
              onChange={this.handleChange('price_seniorpeakseason')}
            />
            <Input
              label="Adult B price"
              name="price_adultpeakseason"
              type="number"
              margin="normal"
              variant="outlined"
              value={!_.isEmpty(activityContent) && activityContent.price_adultpeakseason ? activityContent.price_adultpeakseason : ''}
              onChange={this.handleChange('price_adultpeakseason')}
            />
            <Input
              label="Child price"
              name="price_childpeakseason"
              type="number"
              margin="normal"
              variant="outlined"
              value={!_.isEmpty(activityContent) && activityContent.price_childpeakseason ? activityContent.price_childpeakseason : ''}
              onChange={this.handleChange('price_childpeakseason')}
            />
          </div>
          )}
          <div className="modalActions">
            <ActionButton
              label="save"
              onClick={() => this.onResponseYes()}
              color="reverse"
              disabled={_.isEmpty(activityContent.activity) || _.isEmpty(activityContent.price_adultpeakseason) || _.isEmpty(activityContent.activity_type) || _.isEmpty(activityContent.currency) || _.isEmpty(activityContent.activity_theme)}
            />

            <ActionButton
              label="cancel"
              onClick={() => this.onResponseNo()}
              color="reverse"
            />

          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalActivity
