import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import checked from '../../assets/images/checked.png'
import checked2x from '../../assets/images/checked@2x.png'
import checked3x from '../../assets/images/checked@3x.png'
import unchecked from '../../assets/images/unchecked.png'
import unchecked2x from '../../assets/images/unchecked@2x.png'
import unchecked3x from '../../assets/images/unchecked@3x.png'

import { CssGrid } from '../../configCSS'
import {
  Template,
  Stepper,
  Input,
  ActionButton,
  RedirectionButton,
  Loader,
} from '../../components'
import {
  DaySelect,
  ReservationListActivity,
} from './component'
import getProgressionBooking from './utils/progression'


class TourOperator extends PureComponent {
  static propTypes = {
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    idTripSelected: PropTypes.number,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
    RESET_TOUR_OPERATOR: PropTypes.func.isRequired,
    PUT_TOUR_OPERATOR: PropTypes.func.isRequired,
    PUT_TRIP_BILLING_DEPOSIT: PropTypes.func.isRequired,
    PUT_TRIP_CHECK_ACTIVITY: PropTypes.func.isRequired,
    PUT_INFORMATION_COMMENT: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object,
    tripActivities: PropTypes.object,
    tripDetails: PropTypes.array,
    tripContent: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripPrice: PropTypes.array,
    tripRoom: PropTypes.array,
    tripBilling: PropTypes.object,
    tripAccomodation: PropTypes.array,
    roomList: PropTypes.array,
    putTourOperator: PropTypes.object,
    putInformationComment: PropTypes.object,
    seasonByActivityAll: PropTypes.array,
    tripSeasonByAccomodation: PropTypes.array,
    tripRoomOpt1: PropTypes.array,
    tripRoomOpt2: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    GET_TRIP_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    GET_TRIP_ROOM: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT1: PropTypes.func.isRequired,
    GET_TRIP_ROOM_OPT2: PropTypes.func.isRequired,
  }

  static defaultProps = {
    idTripSelected: null,
    userAccount: {},
    tripDetails: [],
    tripContent: [],
    seasonByActivityAll: [],
    tripAllDaysAccomodations: [],
    tripRoom: [],
    tripRoomOpt1: [],
    tripRoomOpt2: [],
    tripPrice: [],
    tripBilling: {},
    tripActivities: {},
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    roomList: [],
    putTourOperator: {},
    putInformationComment: {},
    tripSeasonByAccomodation: [],
  }

  constructor(props) {
    super(props);
    this.displayValidMessage = this.displayValidMessage.bind(this)
    this.getAllTripInformationLoad = this.getAllTripInformationLoad.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateTripPrice = this.updateTripPrice.bind(this)
    this.updateTripContent = this.updateTripContent.bind(this)
    this.sendDaySelected = this.sendDaySelected.bind(this)
    this.sendDayIndexSelected = this.sendDayIndexSelected.bind(this)
    this.changeTripPrice = this.changeTripPrice.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.changeTripContent = this.changeTripContent.bind(this)
    this.changeActivityCheck = this.changeActivityCheck.bind(this)
    this.changeActivityComment = this.changeActivityComment.bind(this)
    this.resetValidation = this.resetValidation.bind(this)
    this.validate = this.validate.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isValidated: true,
      dayIndexSelected: null,
      daySelected: null,
      hasChangedCommentDeposit: false,
      tripPriceState: [],
      tripActivitiesState: [],
      tripBilling: {},
      tripContentState: [],
    }
  }

  componentDidMount() {
    const {
      history,
      userAccount,
      LOADER,
      tripDetails,
      tripBilling,
      tripAllDaysAccomodations,
      tripPrice,
      tripContent,
      tripActivities,
      GET_USER_ACCOUNT,
    } = this.props
    const {
      userId,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(tripContent)) {
      LOADER(true)
      this.getAllTripInformationLoad(tripContent)
      LOADER(false)
    }
    if (_.isEmpty(tripAllDaysAccomodations) || _.isEmpty(tripDetails)) {
      history.push('/trip')
    }
    // check if props exists
    if (_.isEmpty(userAccount)) {
      GET_USER_ACCOUNT(userId)
    }
    if (!_.isEmpty(tripPrice)) {
      this.setState({
        tripPriceState: tripPrice,
      })
    }
    if (!_.isEmpty(tripActivities)) {
      this.setState({
        tripActivitiesState: tripActivities,
      })
    }
    if (!_.isEmpty(tripBilling)) {
      this.setState({ tripBilling })
    }
    if (!_.isEmpty(tripContent)) {
      this.setState({
        tripContentState: tripContent,
      })
    }
  }


  componentDidUpdate() {
    const {
      tripPrice,
      tripContent,
      putTourOperator,
      putInformationComment,
      GET_TRIP_CONTENT,
      RESET_TOUR_OPERATOR,
      VALIDATE_STEP,
    } = this.props
    const {
      tripPriceState,
      tripContentState,
    } = this.state
    if (tripPrice && _.isEmpty(tripPriceState)) {
      this.updateTripPrice(tripPrice)
    }
    if (tripContent && _.isEmpty(tripContentState)) {
      this.updateTripContent(tripContent)
    }
    if ((putTourOperator && putTourOperator.success) || (putInformationComment && putInformationComment.success)) {
      RESET_TOUR_OPERATOR()
      VALIDATE_STEP('TourOperator', true, getProgressionBooking(tripPriceState) / 100)
      GET_TRIP_CONTENT(tripContentState[0].id_circuit)
      this.displayValidMessage()
      this.resetValidMsg()
    }
  }

  async getAllTripInformationLoad(content, message = false) {
    const {
      GET_TRIP_ACCOMODATION,
      GET_TRIP_ACCOMODATION_OPT1,
      GET_TRIP_ACCOMODATION_OPT2,
      GET_TRIP_ROOM,
      GET_TRIP_ROOM_OPT1,
      GET_TRIP_ROOM_OPT2,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripRoom,
      tripRoomOpt1,
      tripRoomOpt2,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripAccomodation)) await GET_TRIP_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripAccomodationOpt1)) await GET_TRIP_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripAccomodationOpt2)) await GET_TRIP_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripRoom)) await GET_TRIP_ROOM(content[0].type_chambre, content[0].hebergement)
        if (_.isEmpty(tripRoomOpt1)) await GET_TRIP_ROOM_OPT1(content[0].type_chambre_opt1, content[0].hebergement_opt1)
        if (_.isEmpty(tripRoomOpt2)) await GET_TRIP_ROOM_OPT2(content[0].type_chambre_opt2, content[0].hebergement_opt2)


        if (message) {
          this.setState({
            isValidated: true,
            validMsg: 'Changes were successfully recorded !',
          }, () => this.resetValidMsg())
        }
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  updateTripPrice = (tripPrice) => {
    this.setState({
      tripPriceState: tripPrice,
    })
  }

  updateTripContent = (tripContent) => {
    this.setState({
      tripContentState: tripContent,
    })
  }

  displayValidMessage = () => {
    this.setState({
      errorMsg: '',
      validMsg: 'Modification was successfully recorded !',
      isValidated: true,
      hasChangedCommentDeposit: false,
    })
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  sendDayIndexSelected = (rank) => {
    this.setState({
      dayIndexSelected: rank,
    })
  }

  sendDaySelected = (day) => {
    this.setState({
      daySelected: day,
    })
  }

   changeTripPrice = (day) => {
     const { tripPriceState } = this.state
     this.setState({
       tripPriceState: tripPriceState.map(t => (t.id_price === day.id_price ? Object.assign({}, t, day) : t)),
     })
   }

  changeTripContent = (informationKey, informationComment) => {
    const { tripContentState } = this.state
    this.setState({
      tripContentState: [{
        ...tripContentState[0],
        [informationKey]: informationComment,
      }],
    })
  }

  changeActivityCheck = (key, day) => {
    this.setState((prevState) => {
      const { tripActivitiesState } = prevState;
      const activities = tripActivitiesState[day];
      const activity = activities[key];
      const updatedActivity = { ...activity, activityCheck: activity.activityCheck === 1 ? 0 : 1 };
      const updatedActivities = [...activities.slice(0, key), updatedActivity, ...activities.slice(key + 1)];
      const updatedTripActivitiesState = { ...tripActivitiesState, [day]: updatedActivities };
      return { tripActivitiesState: updatedTripActivitiesState };
    });
  }

  changeActivityComment = (key, day, commentValue) => {
    this.setState((prevState) => {
      const { tripActivitiesState } = prevState;
      const activities = tripActivitiesState[day];
      const activity = activities[key];
      const updatedActivity = { ...activity, comment: commentValue };
      const updatedActivities = [...activities.slice(0, key), updatedActivity, ...activities.slice(key + 1)];
      const updatedTripActivitiesState = { ...tripActivitiesState, [day]: updatedActivities };
      return { tripActivitiesState: updatedTripActivitiesState };
    });
  }


  resetValidation = () => {
    this.setState({
      isValidated: false,
      hasChangedCommentDeposit: true,
    })
  }

  goTo = (link) => {
    const {
      history,
      tripDetails,
    } = this.props
    history.push(link, {
      idTrip: tripDetails[0].id_devis,
      source: 'tourOperator',
    })
  }

  // save all new informations for this user
  handleChange = field => (event) => {
    const { tripBilling } = this.state
    this.setState({
      tripBilling: {
        ...tripBilling,
        [field]: event.target.value,
      },
      [`${field}Error`]: false,
      isValidated: false,
      errorMsg: null,
      hasChangedCommentDeposit: true,
    })
  }

  handleCheck = field => () => {
    const { tripBilling } = this.state

    this.setState({
      tripBilling: {
        ...tripBilling,
        [field]: tripBilling[field] === 1 ? 0 : 1,
      },
      [`${field}Error`]: false,
      errorMsg: null,
      hasChangedCommentDeposit: true,
      isValidated: false,
    })
  }

  validate = (e) => {
    e.preventDefault()
    const {
      token,
      tripPriceState,
      tripBilling,
      tripContentState,
      tripActivitiesState,
    } = this.state
    const {
      idTripSelected,
      PUT_TOUR_OPERATOR,
      PUT_TRIP_BILLING_DEPOSIT,
      PUT_TRIP_CHECK_ACTIVITY,
      PUT_INFORMATION_COMMENT,
    } = this.props
    const billingDetails = {}
    billingDetails.depositCheck = tripBilling.depositCheck
    billingDetails.depositComment = tripBilling.depositComment
    billingDetails.soldCheck = tripBilling.soldCheck
    billingDetails.soldComment = tripBilling.soldComment
    PUT_TRIP_BILLING_DEPOSIT(token, idTripSelected, billingDetails)
    PUT_TRIP_CHECK_ACTIVITY(token, tripActivitiesState)

    const informationComment = {
      informationArrivalComment: tripContentState[0].informationArrivalComment,
      informationDepartureComment: tripContentState[0].informationDepartureComment,
      idCircuit: tripContentState[0].id_circuit,
    }

    PUT_TOUR_OPERATOR(token, tripPriceState)
    PUT_INFORMATION_COMMENT(token, informationComment)
  }

  goToSchedule = (data = {}) => {
    const { history } = this.props
    history.push('Schedule', { ...data })
  }


  render() {
    const {
      validMsg,
      errorMsg,
      isValidated,
      isLoading,
      dayIndexSelected,
      tripActivitiesState,
      tripContentState,
      daySelected,
      hasChangedCommentDeposit,
      tripBilling,
    } = this.state
    const {
      tripDetails,
      tripContent,
      tripAllDaysAccomodations,
      tripRoom,
      tripSeasonByAccomodation,
      seasonByActivityAll,
      tripAccomodation,
      roomList,
    } = this.props
    const nomCircuit = tripDetails[0] && tripDetails[0].nom_devis ? tripDetails[0].nom_devis : ''
    const tripActivitiesFlat = [...new Set(Object.values(tripActivitiesState)
      .flat())];
    const nbActivites = tripActivitiesFlat.length;
    const nbActivitesFaites = tripActivitiesFlat.filter(activite => activite.activityCheck === 1).length;
    const pourcentageDone = Math.round(nbActivitesFaites / nbActivites * 100);
    const classContainer = isLoading ? 'container2' : 'container'

    return (
      <div className={classContainer}>
        <Template>
          <div className="requestContainer">
            <Stepper saveData={dest => this.saveData(dest)} />
            <div className="dividerStepper" />
            <div className="container">
              <div className="title-container">
                <h2>Tour Operator        </h2>
                <div className="redirection-button-container">
                  <h2>Reservation Status = {pourcentageDone}%</h2>
                  <RedirectionButton
                    label="Travelers"
                    goTo={() => this.goTo('traveler-management')}
                  />
                  <RedirectionButton
                    label="Schedule"
                    goTo={() => this.goToSchedule({
                      source: 'tourOperator',
                      nomCircuit,
                    })}

                  />
                </div>
              </div>
              {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              {!_.isEmpty(tripAllDaysAccomodations) && !_.isEmpty(tripContent) && (
                <div>
                  <CssGrid
                    container
                    spacing={1}
                  >
                    <CssGrid item md="auto" xs={12}>
                      <Input
                        value="Deposit payed by client?"
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    <CssGrid item md={1} xs={12}>
                      <Input
                        id="deposittopay"
                        label="deposit to pay"
                        value={`${tripBilling.deposit} ${tripBilling.currency}`}
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    <CssGrid item md={2} xs={12}>
                      <Input
                        id="depositComment"
                        label="depositComment"
                        value={!_.isEmpty(tripBilling) && tripBilling.depositComment ? tripBilling.depositComment : ''}
                        onChange={this.handleChange('depositComment')}
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    <CssGrid item md={1} xs={12} className="deposit-item-checkbox" onClick={this.handleCheck('depositCheck')}>
                      {tripBilling.depositCheck === 1
                        ? (
                          <img className="imgChecked" src={checked} srcSet={`${checked2x} 2x, ${checked3x} 3x`} alt="Checked" />
                        )
                        : (
                          <img className="imgChecked" src={unchecked} srcSet={`${unchecked2x} 2x, ${unchecked3x} 3x`} alt="Checked" />
                        )
                      }
                    </CssGrid>
                    <CssGrid item md="auto" xs={12}>
                      <Input
                        value="Sold payed by client?"
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    <CssGrid item md={1} xs={12}>
                      <Input
                        id="soldtopay"
                        label="sold to pay (trip price - deposit) "
                        value={`${tripBilling.sellingPrice - tripBilling.deposit} ${tripBilling.currency}`}
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    <CssGrid item md={2} xs={12}>
                      <Input
                        id="soldComment"
                        label="soldComment"
                        value={!_.isEmpty(tripBilling) && tripBilling.soldComment ? tripBilling.soldComment : ''}
                        onChange={this.handleChange('soldComment')}
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    <CssGrid item md={1} xs={12} className="deposit-item-checkbox" onClick={this.handleCheck('soldCheck')}>
                      {tripBilling.soldCheck === 1
                        ? (
                          <img className="imgChecked" src={checked} srcSet={`${checked2x} 2x, ${checked3x} 3x`} alt="Checked" />
                        )
                        : (
                          <img className="imgChecked" src={unchecked} srcSet={`${unchecked2x} 2x, ${unchecked3x} 3x`} alt="Checked" />
                        )
              }
                    </CssGrid>
                  </CssGrid>
                  <DaySelect
                    dayIndexSelected={dayIndexSelected || 0}
                    daySelected={daySelected || tripAllDaysAccomodations[0]}
                    tripAllDays={tripAllDaysAccomodations}
                    tripContent={tripContent}
                    tripDetails={tripDetails}
                    sendDayIndexSelected={rank => this.sendDayIndexSelected(rank)}
                    sendDaySelected={day => this.sendDaySelected(day)}
                  />
                </div>
              )}
              {tripAccomodation && tripRoom && !_.isEmpty(tripAllDaysAccomodations) && !_.isEmpty(tripContentState) && (
              <ReservationListActivity
                dayIndexSelected={dayIndexSelected || 0}
                tripActivities={tripActivitiesState}
                tripContent={tripContentState}
                tripDetails={tripDetails}
                tripAllDays={tripAllDaysAccomodations}
                tripAccomodation={tripAccomodation}
                tripRoom={tripRoom}
                roomList={roomList}
                tripSeasonByAccomodation={tripSeasonByAccomodation}
                seasonByActivityAll={seasonByActivityAll}
                nbOfDays={tripAllDaysAccomodations.length}
                changeTripContent={(key, value) => this.changeTripContent(key, value)}
                changeActivityCheck={(key, day) => this.changeActivityCheck(key, day)}
                changeActivityComment={(key, day, commentValue) => this.changeActivityComment(key, day, commentValue)}
                resetValidation={() => this.resetValidation()}
              />
              )}
              <ActionButton
                label="Validate"
                disabled={isValidated}
                onClick={e => this.validate(e)}
              />
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={hasChangedCommentDeposit && !isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  userAccount: state.userAccountReducer.userAccount,
  idTripSelected: state.api.idTripSelected,
  tripContent: state.api.tripContent,
  tripPrice: state.billingReducer.tripPrice,
  seasonByActivityAll: state.api.seasonByActivityAll,
  additionalColumnPrice: state.billingReducer.additionalColumnPrice,
  tripBilling: state.billingReducer.tripBilling,
  putTourOperator: state.tourOperatorReducer.putTourOperator,
  tripActivities: state.billingReducer.tripActivities,
  putInformationComment: state.tourOperatorReducer.putInformationComment,
  tripDetails: state.api.tripDetails,
  tripAccomodation: state.api.tripAccomodation,
  roomList: state.api.roomList,
  tripRoom: state.api.tripRoom,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  tripSeasonByAccomodation: state.api.tripSeasonByAccomodation,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  GET_TRIP_PRICE: (token, idDevis) => dispatch({
    type: 'GET_TRIP_PRICE',
    token,
    idDevis,
  }),
  GET_TRIP_BILLING: (token, idDevis) => dispatch({
    type: 'GET_TRIP_BILLING',
    token,
    idDevis,
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  PUT_TOUR_OPERATOR: (token, tripPrice) => dispatch({
    type: 'PUT_TOUR_OPERATOR',
    token,
    tripPrice,
  }),
  RESET_TOUR_OPERATOR: () => dispatch({
    type: 'RESET_TOUR_OPERATOR',
  }),
  PUT_INFORMATION_COMMENT: (token, informationComment) => dispatch({
    type: 'PUT_INFORMATION_COMMENT',
    token,
    informationComment,
  }),
  PUT_TRIP_CHECK_ACTIVITY: (token, tripActivities) => dispatch({
    type: 'PUT_TRIP_CHECK_ACTIVITY',
    token,
    tripActivities,
  }),
  VALIDATE_STEP: (page, value, progression) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
    progression,
  }),
  PUT_TRIP_BILLING_DEPOSIT: (token, idDevis, billingDetails) => dispatch({
    type: 'PUT_TRIP_BILLING_DEPOSIT',
    token,
    idDevis,
    billingDetails,
  }),
  GET_TRIP_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_ROOM: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT1: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT1',
    tripRoomList,
    tripAccoList,
  }),
  GET_TRIP_ROOM_OPT2: (tripRoomList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ROOM_OPT2',
    tripRoomList,
    tripAccoList,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TourOperator))
