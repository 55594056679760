import {
  GET_TRIP_PRICE_RESULTS,
  PUT_TRIP_PRICE_RESULTS,
  DELETE_ROW_PRICE_RESULTS,
  RESET_DATA_COLUMN_PRICE_RESULTS,
  RESET_PUT_TRIP_PRICE,
  GET_ADDITIONAL_COLUMN_PRICE_RESULTS,
  PUT_ADDITIONAL_COLUMN_PRICE_RESULTS,
  RESET_PUT_ADDITIONAL_COLUMN_PRICE,
  GET_TRIP_BILLING_RESULTS,
  PUT_TRIP_BILLING_RESULTS,
  RESET_PUT_TRIP_BILLING,
  RESET_PUT_TRIP_CONTENT_ACCO,
  REORDER_DAYS_RANK_RESULTS,
  RESET_REORDER_STATUS,
  GET_TRIP_ACTIVITIES_RESULTS,
  PUT_TRIP_DEFAULT_ACTIVITIES_RESULTS,
  RESET_PUT_TRIP_DEFAULT_ACTIVITIES,
  PUT_TRIP_ACTIVITY_RESULTS,
  PUT_TRIP_CONTENT_ACCO_RESULTS,
  PUT_TRIP_CHECK_ACTIVITY_RESULTS,
  PUT_TRIP_TRAVELLER_ACTIVITY_RESULTS,
  PUT_TRIP_PRICE_ACTIVITY_RESULTS,
  PUT_TRIP_ACCOMODATION_ACTIVITY_RESULTS,
  RESET_PUT_TRIP_ACTIVITY,
  RESET_TRIP_ACTIVITIES,
  RESET_PUT_TRIP_ACCOMODATION_ACTIVITY,
  DELETE_TRIP_ACTIVITIES_RESULTS,
  DELETE_TRIP_ACTIVITY_RESULTS,
  RESET_DELETE_TRIP_ACTIVITIES,
} from './actions'
import {
  RESET_APP,
  RESET_TRIP_INFORMATION,
} from '../../../components/api/actions'

const initialState = {
  tripPrice: [],
  putTripPrice: null,
  deleteRowPrice: null,
  resetDataColumnPrice: null,
  additionalColumnPrice: {},
  putAdditionalColumnPrice: null,
  tripBilling: {},
  putTripBilling: null,
  reorderStatus: {},
  tripActivities: {},
  putTripDefaultActivities: {},
  putTripTravellerActivity: {},
  putTripActivity: {},
  putTripContentAcco: {},
  deleteTripActivities: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case RESET_TRIP_INFORMATION:
      return {
        ...state,
        tripPrice: [],
        putTripPrice: null,
        deleteRowPrice: null,
        tripBilling: {},
        putTripBilling: null,
        additionalColumnPrice: {},
        putAdditionalColumnPrice: null,
        reorderStatus: {},
        tripActivities: {},
        putTripDefaultActivities: {},
        putTripActivity: {},
        putTripContentAcco: {},
        deleteTripActivities: {},
      }
    // ********* TABLE PRICE ***************
    case GET_TRIP_PRICE_RESULTS:
      return {
        ...state,
        tripPrice: action.tripPrice.sort((a, b) => a.dayRank - b.dayRank),
      }
    case PUT_TRIP_PRICE_RESULTS:
      return {
        ...state,
        putTripPrice: action.putTripPrice,
      }
    case DELETE_ROW_PRICE_RESULTS:
      return {
        ...state,
        deleteRowPrice: action.deleteRowPrice,
      }
    case RESET_DATA_COLUMN_PRICE_RESULTS:
      return {
        ...state,
        resetDataColumnPrice: action.resetDataColumnPrice,
      }
    case RESET_PUT_TRIP_PRICE:
      return {
        ...state,
        putTripPrice: null,
        deleteRowPrice: null,
      }
    case GET_ADDITIONAL_COLUMN_PRICE_RESULTS:
      return {
        ...state,
        additionalColumnPrice: action.additionalColumnPrice[0],
      }
    case PUT_ADDITIONAL_COLUMN_PRICE_RESULTS:
      return {
        ...state,
        putAdditionalColumnPrice: action.putAdditionalColumnPrice,
      }
    case RESET_PUT_ADDITIONAL_COLUMN_PRICE:
      return {
        ...state,
        putAdditionalColumnPrice: null,
      }
    case REORDER_DAYS_RANK_RESULTS:
      return {
        ...state,
        reorderStatus: action.reorderStatus,
      }

    case RESET_REORDER_STATUS:
      return {
        ...state,
        reorderStatus: {},
      }
      // ************** ACCO ROOM ********************
    case PUT_TRIP_CONTENT_ACCO_RESULTS:
      return {
        ...state,
        putTripContentAcco: action.putTripContentAcco,
      }
    case RESET_PUT_TRIP_CONTENT_ACCO:
      return {
        ...state,
        putTripContentAcco: null,
      }

    // ************** BILLING ********************
    case GET_TRIP_BILLING_RESULTS:
      return {
        ...state,
        tripBilling: action.tripBilling[0],
      }
    case PUT_TRIP_BILLING_RESULTS:
      return {
        ...state,
        putTripBilling: action.putTripBilling,
      }
    case RESET_PUT_TRIP_BILLING:
      return {
        ...state,
        putTripBilling: null,
      }
    // ************ ACTIVITIES ******************
    case GET_TRIP_ACTIVITIES_RESULTS:
      return {
        ...state,
        tripActivities: action.tripActivities.reduce((r, a) => {
          r[a.day_index] = r[a.day_index] || [];
          r[a.day_index].push(a);
          return r;
        }, Object.create(null)),
      }
    case PUT_TRIP_DEFAULT_ACTIVITIES_RESULTS:
      return {
        ...state,
        putTripDefaultActivities: action.putTripDefaultActivities,
      }
    case RESET_PUT_TRIP_DEFAULT_ACTIVITIES:
      return {
        ...state,
        putTripDefaultActivities: null,
      }
    case RESET_TRIP_ACTIVITIES:
      return {
        ...state,
        tripActivities: {},
      }
    case PUT_TRIP_ACTIVITY_RESULTS:
      return {
        ...state,
        putTripActivity: action.putTripActivity,
      }
    case PUT_TRIP_CHECK_ACTIVITY_RESULTS:
      return {
        ...state,
        putTripCheckActivity: action.putTripCheckActivity,
      }
    case PUT_TRIP_TRAVELLER_ACTIVITY_RESULTS:
      return {
        ...state,
        putTripTravellerActivity: action.putTripTravellerActivity,
      }
    case PUT_TRIP_PRICE_ACTIVITY_RESULTS:
      return {
        ...state,
        putTripPriceActivity: action.putTripPriceActivity,
      }
    case PUT_TRIP_ACCOMODATION_ACTIVITY_RESULTS:
      return {
        ...state,
        putTripAccomodationActivity: action.putTripAccomodationActivity,
      }
    case RESET_PUT_TRIP_ACTIVITY:
      return {
        ...state,
        putTripActivity: {},
      }
    case RESET_PUT_TRIP_ACCOMODATION_ACTIVITY:
      return {
        ...state,
        putTripAccomodationActivity: {},
        putTripPriceActivity: {},
        putTripTravellerActivity: {},
      }
    case DELETE_TRIP_ACTIVITIES_RESULTS:
      return {
        ...state,
        deleteTripActivities: action.deleteTripActivities,
      }
    case DELETE_TRIP_ACTIVITY_RESULTS:
      return {
        ...state,
        deleteTripActivity: action.deleteTripActivity,
      }
    case RESET_DELETE_TRIP_ACTIVITIES:
      return {
        ...state,
        deleteTripActivities: {},
        deleteTripActivity: {},
      }
    default:
      return state
  }
}
