/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'

import app from '../../../package.json'
import detailsTrip from '../../assets/images/detailsTrip.png'
import detailsTrip2x from '../../assets/images/detailsTrip@2x.png'
import detailsTrip3x from '../../assets/images/detailsTrip@3x.png'
import routes from '../../assets/images/routes.png'
import routes2x from '../../assets/images/routes@2x.png'
import routes3x from '../../assets/images/routes@3x.png'
import extension from '../../assets/images/extension.png'
import extension2x from '../../assets/images/extension@2x.png'
import extension3x from '../../assets/images/extension@3x.png'
import print from '../../assets/images/print.png'
import print2x from '../../assets/images/print@2x.png'
import print3x from '../../assets/images/print@3x.png'
import billing from '../../assets/images/billing.png'
import billing2x from '../../assets/images/billing@2x.png'
import billing3x from '../../assets/images/billing@3x.png'
import booking from '../../assets/images/booking.png'
import booking2x from '../../assets/images/booking@2x.png'
import booking3x from '../../assets/images/booking@3x.png'
import getProgressionBooking from '../../pages/tourOperator/utils/progression'

const step = [
  {
    key: 1,
    name: 'TripDetails',
    title: 'Details',
    img: detailsTrip,
    img2x: detailsTrip2x,
    img3x: detailsTrip3x,
    link: 'tripDetails',
    isValidated: false,
  },
  {
    key: 2,
    name: 'DaysList',
    title: 'Route',
    img: routes,
    img2x: routes2x,
    img3x: routes3x,
    link: 'daysList',
    isValidated: false,
  },
  {
    key: 3,
    name: 'TripExtension',
    title: 'Sites & Visit',
    img: extension,
    img2x: extension2x,
    img3x: extension3x,
    link: 'tripSite',
    isValidated: false,
  },
  {
    key: 4,
    name: 'Billing',
    title: 'Billing',
    img: billing,
    img2x: billing2x,
    img3x: billing3x,
    link: 'billing',
    isValidated: false,
  },
  {
    key: 5,
    name: 'TourOperator',
    title: 'TourOperator',
    img: booking,
    img2x: booking2x,
    img3x: booking3x,
    link: 'tourOperator',
    isValidated: false,
  },
  {
    key: 6,
    name: 'Print',
    title: 'Print',
    img: print,
    img2x: print2x,
    img3x: print3x,
    link: 'print',
    isValidated: false,
  },
]

class Stepper extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    activeStep: PropTypes.string,
    isTripStepValidated: PropTypes.bool,
    isTripDetailsStepValidated: PropTypes.bool,
    isDaysListStepValidated: PropTypes.bool,
    isTripExtensionStepValidated: PropTypes.bool,
    isBillingStepValidated: PropTypes.bool,
    isTourOperatorStepValidated: PropTypes.bool,
    tourOperatorStepProgression: PropTypes.number,
    size: PropTypes.number,
    saveData: PropTypes.func,
    history: PropTypes.object.isRequired,
    informationsContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: true,
    activeStep: null,
    isTripStepValidated: false,
    isTripDetailsStepValidated: false,
    isDaysListStepValidated: false,
    isTripExtensionStepValidated: false,
    isBillingStepValidated: false,
    isTourOperatorStepValidated: false,
    tourOperatorStepProgression: 0,
    informationsContent: {},
    size: 60,
    saveData: () => {},
  }

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this)
    this.fillBackground = this.fillBackground.bind(this)
    this.disabledStep = this.disabledStep.bind(this)
    this.progressiveBackgroundColor = this.progressiveBackgroundColor.bind(this)
    this.state = {
      isTripStepValidated: false,
      isTripDetailsStepValidated: false,
      isDaysListStepValidated: false,
      isTripExtensionStepValidated: false,
      isBillingStepValidated: false,
    };
  }

  componentDidMount() {
    const {
      isTripStepValidated,
      isTripDetailsStepValidated,
      isDaysListStepValidated,
      isTripExtensionStepValidated,
      isBillingStepValidated,
    } = this.props
    // tripExtension is only available in 'cielYunnan' template. Otherwise is always true
    this.setState({
      isTripStepValidated,
      isTripDetailsStepValidated,
      isDaysListStepValidated,
      isTripExtensionStepValidated,
      isBillingStepValidated,
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      isTripStepValidated: isTripStepValidatedOld,
      isTripDetailsStepValidated: isTripDetailsStepValidatedOld,
      isDaysListStepValidated: isDaysListStepValidatedOld,
      isTripExtensionStepValidated: isTripExtensionStepValidatedOld,
      isBillingStepValidated: isBillingStepValidatedOld,
    } = this.state
    const {
      isTripStepValidated,
      isTripDetailsStepValidated,
      isDaysListStepValidated,
      isTripExtensionStepValidated,
      isBillingStepValidated,
      informationsContent,
    } = nextProps
    const { theme_devis } = informationsContent

    if (!_.isEqual(isTripStepValidated, isTripStepValidatedOld)) {
      this.setState({
        isTripStepValidated,
      })
    }
    if (!_.isEqual(isTripDetailsStepValidated, isTripDetailsStepValidatedOld)) {
      this.setState({
        isTripDetailsStepValidated,
      })
    }
    if (!_.isEqual(isDaysListStepValidated, isDaysListStepValidatedOld)) {
      this.setState({
        isDaysListStepValidated,
      })
    }
    if (!_.isEqual(isTripExtensionStepValidated, isTripExtensionStepValidatedOld)) {
      this.setState({
        isTripExtensionStepValidated,
      })
    }
    if (!_.isEqual(isBillingStepValidated, isBillingStepValidatedOld)) {
      this.setState({
        isBillingStepValidated,
      })
    }
    // tripExtension is only available in 'prenium' template. Otherwise is always true
    if (theme_devis && ['Ciel'].indexOf(theme_devis) === -1) {
      this.setState({
        isTripExtensionStepValidated: true,
      })
    }
  }

  goTo = (dest) => {
    const {
      saveData,
      activeStep,
      history,
      isLoading,
    } = this.props
    if (isLoading) return null
    if (activeStep === 'tripDetails' || activeStep === 'daysList' || activeStep === 'tripExtension') saveData(dest)
    else history.push(dest)
    return null
  }

  progressiveBackgroundColor = () => {
    const {
      tourOperatorStepProgression,
    } = this.props
    if (tourOperatorStepProgression === 1) return '#0bcdd6'
    // attenuation by 2 to accentuate the difference between complete and imcomplete
    return `rgba(11, 205, 214, ${tourOperatorStepProgression / 2})`
  }

  fillBackground = (data) => {
    const primary = '#0bcdd6'
    const {
      isTripStepValidated,
      isTripDetailsStepValidated,
      isDaysListStepValidated,
      isTripExtensionStepValidated,
      isBillingStepValidated,
    } = this.state
    const {
      isTourOperatorStepValidated,
    } = this.props
    switch (data) {
      case 'isTripStepValidated':
        return isTripStepValidated === true ? primary : null
      case 'isTripDetailsStepValidated':
        return isTripDetailsStepValidated === true ? primary : null
      case 'isDaysListStepValidated':
        return isDaysListStepValidated === true ? primary : null
      case 'isTripExtensionStepValidated':
        return isTripExtensionStepValidated === true ? primary : null
      case 'isBillingStepValidated':
        return isBillingStepValidated === true ? primary : null
      case 'isTourOperatorStepValidated':
        return isTourOperatorStepValidated === true ? this.progressiveBackgroundColor() : null
      case 'isPrintStepValidated':
        return isTripStepValidated && isTripDetailsStepValidated && isDaysListStepValidated && isTripExtensionStepValidated ? primary : null
      default:
        break;
    }
    return null
  }

  disabledStep = (btn) => {
    const {
      isTripStepValidated,
      isTripDetailsStepValidated,
      isDaysListStepValidated,
      isTripExtensionStepValidated,
    } = this.state

    switch (btn) {
      case 'TripDetails':
        return isTripStepValidated
      case 'DaysList':
      case 'TripExtension':
        return isTripStepValidated && isTripDetailsStepValidated
      case 'Billing':
        return isTripStepValidated && isTripDetailsStepValidated && isDaysListStepValidated
      case 'TourOperator':
        return isTripStepValidated && isTripDetailsStepValidated && isDaysListStepValidated
      case 'Print':
        return isTripStepValidated && isTripDetailsStepValidated && isDaysListStepValidated && isTripExtensionStepValidated
      default:
        break;
    }
    return null
  }

  render() {
    const {
      size,
      activeStep,
      informationsContent,
      isLoading,
    } = this.props
    const {
      isTripStepValidated,
      isTripDetailsStepValidated,
      isDaysListStepValidated,
      isTripExtensionStepValidated,
      isBillingStepValidated,
    } = this.state
    const { theme_devis } = informationsContent

    return (
      <div className="stepperMain">
        {_.map(step, (l, k) => {
          // Don't display Extension menu for template 'standard' and 'duyen' and 'duyen-template'
          if (['Ciel'].indexOf(theme_devis) === -1 && l.title === 'Sites & Visit') return null
          return (
            <div className="stepperContainer" key={k}>
              <button
                type="button"
                className={`stepper ${l.link === activeStep ? 'isActive' : null} ${!this.disabledStep(l.name) || isLoading ? 'isDisabled' : null}`}
                disabled={!this.disabledStep(l.name) || isLoading}
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                  borderRadius: `${size / 2}px`,
                  backgroundColor: this.fillBackground(`is${l.name}StepValidated`),
                }}
                onClick={() => this.goTo(l.link)}
              >
                <img className="imgStepper" src={l.img} srcSet={`${l.img2x} 2x, ${l.img3x} 3x`} alt="" />
              </button>
              <p className="titleStepper">{l.title}</p>
            </div>
          )
        })}
        <div className="version">
          {`v${app.version}`}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeStep: state.api.activeStep,
  isTripStepValidated: state.api.isTripStepValidated,
  isTripDetailsStepValidated: state.api.isTripDetailsStepValidated,
  isDaysListStepValidated: state.api.isDaysListStepValidated,
  isTripExtensionStepValidated: state.api.isTripExtensionStepValidated,
  isBillingStepValidated: state.api.isBillingStepValidated,
  isTourOperatorStepValidated: state.api.isTourOperatorStepValidated,
  tourOperatorStepProgression: state.api.tourOperatorStepProgression,
  isLoading: state.api.isLoading,
  informationsContent: state.informationsReducer.informationsContent,
})

export default withRouter(connect(mapStateToProps)(Stepper))
