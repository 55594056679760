export const GET_TRIP_PRICE_RESULTS = 'GET_TRIP_PRICE_RESULTS'
export const PUT_TRIP_PRICE_RESULTS = 'PUT_TRIP_PRICE_RESULTS'
export const DELETE_ROW_PRICE_RESULTS = 'DELETE_ROW_PRICE_RESULTS'
export const RESET_DATA_COLUMN_PRICE_RESULTS = 'RESET_DATA_COLUMN_PRICE_RESULTS'
export const RESET_PUT_TRIP_PRICE = 'RESET_PUT_TRIP_PRICE'
export const GET_ADDITIONAL_COLUMN_PRICE_RESULTS = 'GET_ADDITIONAL_COLUMN_PRICE_RESULTS'
export const PUT_ADDITIONAL_COLUMN_PRICE_RESULTS = 'PUT_ADDITIONAL_COLUMN_PRICE_RESULTS'
export const RESET_PUT_ADDITIONAL_COLUMN_PRICE = 'RESET_PUT_ADDITIONAL_COLUMN_PRICE'
export const GET_TRIP_BILLING_RESULTS = 'GET_TRIP_BILLING_RESULTS'
export const PUT_TRIP_BILLING_RESULTS = 'PUT_TRIP_BILLING_RESULTS'
export const RESET_PUT_TRIP_BILLING = 'RESET_PUT_TRIP_BILLING'
export const REORDER_DAYS_RANK_RESULTS = 'REORDER_DAYS_RANK_RESULTS'
export const RESET_PUT_TRIP_CONTENT_ACCO = 'RESET_PUT_TRIP_CONTENT_ACCO'
export const PUT_TRIP_CONTENT_ACCO_RESULTS = 'PUT_TRIP_CONTENT_ACCO_RESULTS'
export const RESET_REORDER_STATUS = 'RESET_REORDER_STATUS'
export const GET_TRIP_ACTIVITIES_RESULTS = 'GET_TRIP_ACTIVITIES_RESULTS'
export const PUT_TRIP_DEFAULT_ACTIVITIES_RESULTS = 'PUT_TRIP_DEFAULT_ACTIVITIES_RESULTS'
export const RESET_PUT_TRIP_DEFAULT_ACTIVITIES = 'RESET_PUT_TRIP_DEFAULT_ACTIVITIES'
export const RESET_TRIP_ACTIVITIES = 'RESET_TRIP_ACTIVITIES'
export const PUT_TRIP_ACTIVITY_RESULTS = 'PUT_TRIP_ACTIVITY_RESULTS'
export const PUT_TRIP_CHECK_ACTIVITY_RESULTS = 'PUT_TRIP_CHECK_ACTIVITY_RESULTS'
export const PUT_TRIP_TRAVELLER_ACTIVITY_RESULTS = 'PUT_TRIP_TRAVELLER_ACTIVITY_RESULTS'
export const PUT_TRIP_PRICE_ACTIVITY_RESULTS = 'PUT_TRIP_PRICE_ACTIVITY_RESULTS'
export const PUT_TRIP_ACCOMODATION_ACTIVITY_RESULTS = 'PUT_TRIP_ACCOMODATION_ACTIVITY_RESULTS'
export const RESET_PUT_TRIP_ACTIVITY = 'RESET_PUT_TRIP_ACTIVITY'
export const RESET_PUT_TRIP_ACCOMODATION_ACTIVITY = 'RESET_PUT_TRIP_ACCOMODATION_ACTIVITY'
export const DELETE_TRIP_ACTIVITIES_RESULTS = 'DELETE_TRIP_ACTIVITIES_RESULTS'
export const DELETE_TRIP_ACTIVITY_RESULTS = 'DELETE_TRIP_ACTIVITY_RESULTS'
export const RESET_DELETE_TRIP_ACTIVITIES = 'RESET_DELETE_TRIP_ACTIVITIES'

// ************** TABLE PRICE ***************
export const getTripPriceResults = data => ({
  type: GET_TRIP_PRICE_RESULTS,
  tripPrice: data,
})

export const putTripPriceResults = data => ({
  type: PUT_TRIP_PRICE_RESULTS,
  putTripPrice: data,
})

export const resetPutTripPriceResults = () => ({
  type: RESET_PUT_TRIP_PRICE,
})

export const deleteRowPriceResults = data => ({
  type: DELETE_ROW_PRICE_RESULTS,
  deleteRowPrice: data,
})

export const resetDataColumnPriceResults = data => ({
  type: RESET_DATA_COLUMN_PRICE_RESULTS,
  resetDataColumnPrice: data,
})

export const getAdditionalColumnPriceResults = data => ({
  type: GET_ADDITIONAL_COLUMN_PRICE_RESULTS,
  additionalColumnPrice: data,
})

export const putAdditionalColumnPriceResults = data => ({
  type: PUT_ADDITIONAL_COLUMN_PRICE_RESULTS,
  putAdditionalColumnPrice: data,
})

export const resetPutAdditionalColumnPriceResults = () => ({
  type: RESET_PUT_ADDITIONAL_COLUMN_PRICE,
})

export const resetReorderStatus = () => ({
  type: RESET_REORDER_STATUS,
})

// ********** BILLING *********************
export const getTripBillingResults = data => ({
  type: GET_TRIP_BILLING_RESULTS,
  tripBilling: data,
})

export const putTripBillingResults = data => ({
  type: PUT_TRIP_BILLING_RESULTS,
  putTripBilling: data,
})

export const resetPutTripBillingResults = () => ({
  type: RESET_PUT_TRIP_BILLING,
})

export const reorderDaysRankResults = data => ({
  type: REORDER_DAYS_RANK_RESULTS,
  reorderStatus: data,
})

export const resetPutTripContentAccoResults = () => ({
  type: RESET_PUT_TRIP_CONTENT_ACCO,
})

// ********** ACCO ROOM *********************
export const putTripContentAccoResults = data => ({
  type: PUT_TRIP_CONTENT_ACCO_RESULTS,
  putTripContentAcco: data,
})

// ********** ACTIVITIES *********************
export const getTripActivitiesResults = data => ({
  type: GET_TRIP_ACTIVITIES_RESULTS,
  tripActivities: data,
})

export const putTripDefaultActivitiesResults = data => ({
  type: PUT_TRIP_DEFAULT_ACTIVITIES_RESULTS,
  putTripDefaultActivities: data,
})

export const resetPutTripDefaultActivities = () => ({
  type: RESET_PUT_TRIP_DEFAULT_ACTIVITIES,
})
export const resetTripActivities = () => ({
  type: RESET_TRIP_ACTIVITIES,
})

export const putTripActivityResults = data => ({
  type: PUT_TRIP_ACTIVITY_RESULTS,
  putTripActivity: data,
})

export const putTripCheckActivityResults = data => ({
  type: PUT_TRIP_CHECK_ACTIVITY_RESULTS,
  putTripCheckActivity: data,
})

export const putTripTravellerActivityResults = data => ({
  type: PUT_TRIP_TRAVELLER_ACTIVITY_RESULTS,
  putTripTravellerActivity: data,
})

export const putTripPriceActivityResults = data => ({
  type: PUT_TRIP_PRICE_ACTIVITY_RESULTS,
  putTripPriceActivity: data,
})

export const putTripAccomodationActivityResults = data => ({
  type: PUT_TRIP_ACCOMODATION_ACTIVITY_RESULTS,
  putTripAccomodationActivity: data,
})

export const resetPutTripActivity = () => ({
  type: RESET_PUT_TRIP_ACTIVITY,
})

export const resetPutTripAccomodationActivity = () => ({
  type: RESET_PUT_TRIP_ACCOMODATION_ACTIVITY,
})

export const deleteTripActivitiesResults = data => ({
  type: DELETE_TRIP_ACTIVITIES_RESULTS,
  deleteTripActivities: data,
})

export const deleteTripActivityResults = data => ({
  type: DELETE_TRIP_ACTIVITY_RESULTS,
  deleteTripActivity: data,
})


export const resetDeleteTripActivities = () => ({
  type: RESET_DELETE_TRIP_ACTIVITIES,
})
