import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import moment from 'moment'
import { CssGrid } from '../../../configCSS'
import {
  Input,
} from '../../../components'
import checked from '../../../assets/images/checked.png'
import checked2x from '../../../assets/images/checked@2x.png'
import checked3x from '../../../assets/images/checked@3x.png'
import unchecked from '../../../assets/images/unchecked.png'
import unchecked2x from '../../../assets/images/unchecked@2x.png'
import unchecked3x from '../../../assets/images/unchecked@3x.png'

import {
  ReservationColumnHeader,
} from '.'


export default function ReservationListActivity(props) {
  const {
    tripContent,
    tripActivities,
    tripDetails,
    tripAccomodation,
    tripAllDays,
    nbOfDays,
    dayIndexSelected,
    seasonByActivityAll,
    tripSeasonByAccomodation,
    tripRoom,
    roomList,
    resetValidation,
    changeTripContent,
    changeActivityCheck,
    changeActivityComment,
  } = props
  const [informationInput, setInformationInput] = useState({})

  useEffect(() => {
    switch (dayIndexSelected) {
      case 0:
        setInformationInput({
          name: 'informationArrivalComment',
          label: 'Arrival informations',
          value: tripContent[0].informationArrivalComment,
        })
        break;
      case nbOfDays - 1:
        setInformationInput({
          name: 'informationDepartureComment',
          label: 'Departure informations',
          value: tripContent[0].informationDepartureComment,
        })
        break;
      default:
        setInformationInput({})
        break;
    }
  }, [dayIndexSelected, tripContent])

  const handleInformationComment = (e) => {
    const { value } = e.target
    setInformationInput({
      ...informationInput,
      value: value,
    })
    resetValidation()
  }

  const onBlur = () => {
    if (informationInput) {
      changeTripContent(informationInput.name, informationInput.value)
      resetValidation()
    }
  }

  const handleCheck = (key, day) => {
    changeActivityCheck(key, day)
    resetValidation()
  }

  const handleChangeComment = (key, day, e) => {
    const { value } = e.target
    changeActivityComment(key, day, value)
    resetValidation()
  }

  const TabIncJour = tripAllDays ? tripAllDays.map(item => (item.day_duration)) : []
  const IncDay = _.sum(TabIncJour.slice(0, dayIndexSelected))
  const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDay, 'd').format('dddd DD MMMM YYYY'))
    : ''
  const seasonByAccomodation = tripSeasonByAccomodation.filter(c => c.id_hebergement === tripAccomodation[dayIndexSelected].id_hebergement)
  let seasonName = ''
  let PriceRoomCalculated = ''
  const roomTypeArray = tripContent ? tripContent[0].type_chambre.split(',') : []
  const roomsNumberArray = tripContent && tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : null
  const roomTypeForDay = () => {
    const roomsTypeForDay = roomTypeArray[dayIndexSelected] && roomTypeArray[dayIndexSelected] !== '1' ? roomTypeArray[dayIndexSelected].split('-') : []
    const roomsNbForDay = roomsNumberArray && roomsNumberArray[dayIndexSelected] !== 'null' ? roomsNumberArray[dayIndexSelected].split('-') : []
    const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
    const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
    return label.join('')
  }
  const PriceRoom = tripRoom[dayIndexSelected]
  const roomsNbForDay = roomsNumberArray && roomsNumberArray[dayIndexSelected] !== 'null' ? roomsNumberArray[dayIndexSelected].split('-') : []
  let datejour1 = tripDetails[0].number_day_departure ? tripDetails[0].number_day_departure + IncDay : ''
  const annee = _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(dayIndexSelected, 'days').format('YYYY'))

  const ily = ((annee % 4 === 0) && (annee % 100 !== 0)) || (annee % 400 === 0);
  if (ily && (datejour1 > 59)) (datejour1 -= 1)

  if (!_.isEmpty(seasonByAccomodation)) {
    seasonByAccomodation.map((sBA) => {
      if (datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
        seasonName = `price_${sBA.period_name}`
      }
      return null
    })
  }
  if (PriceRoom) {
    PriceRoom.map((PR, index2) => {
      if (PR[`${seasonName}`] && PR[`${seasonName}`] !== 0) {
        PriceRoomCalculated = `${PriceRoomCalculated}  ${((PR[`${seasonName}`] * roomsNbForDay[index2]) * (tripAllDays[dayIndexSelected].day_duration ? tripAllDays[dayIndexSelected].day_duration : 1))}${tripAccomodation[dayIndexSelected].currency}`
      } else {
        PriceRoomCalculated = `${PriceRoomCalculated}  ${((PR.price_peakseason * roomsNbForDay[index2]) * (tripAllDays[dayIndexSelected].day_duration ? tripAllDays[dayIndexSelected].day_duration : 1))}${tripAccomodation[dayIndexSelected].currency}`
      }
      return null
    })
  }
  return (
    <div>
      {!_.isEmpty(informationInput) && (
      <CssGrid item xs={12}>
        <Input
          id="informationInput"
          name={informationInput.name}
          label={informationInput.label}
          type="text"
          multiline
          value={informationInput.value || ''}
          onChange={e => handleInformationComment(e)}
          onBlur={onBlur}
          margin="normal"
          variant="outlined"
        />
      </CssGrid>
      )}
      <div className="reservation-list">
        {tripActivities && tripActivities[dayIndexSelected]
          ? (
            <ReservationColumnHeader
              itemsCheck={tripActivities[dayIndexSelected]}
            />
          )
          : ''
        }


        {tripActivities && tripActivities[dayIndexSelected] && tripActivities[dayIndexSelected].map((tA, key) => {
          const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === tA.id_activity)
          let priceSeasonNameAdult = 0
          let priceSeasonNameChild = 0
          let priceSeasonNameSenior = 0
          let isSeasonFound = false;
          if (!_.isEmpty(seasonByActivities)) {
            seasonByActivities.map((sBA) => {
              if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
                priceSeasonNameAdult = `price_adult${sBA.period_name}`
                priceSeasonNameChild = `price_child${sBA.period_name}`
                priceSeasonNameSenior = `price_senior${sBA.period_name}`
                isSeasonFound = true;
              } else if (!isSeasonFound) {
                priceSeasonNameAdult = 'price_adultpeakseason'
                priceSeasonNameChild = 'price_childpeakseason'
                priceSeasonNameSenior = 'price_seniorpeakseason'
              }
              return null
            })
          } else {
            priceSeasonNameAdult = 'price_adultpeakseason'
            priceSeasonNameChild = 'price_childpeakseason'
            priceSeasonNameSenior = 'price_seniorpeakseason'
          }
          return (
            <CssGrid
              container
              spacing={2}
              className="reservation-item-container"
              key={`container-${tA.id_act_cir}`}
            >
              <CssGrid item key={`title-${tA.id_act_cir}`} md={2} xs={12} className="reservation-item">
                <Input
                  className="input-gray"
                  id={`title-${key}`}
                  name="reservation-title"
                  label=""
                  type="text"
                  multiline
                  value={tA.id_activity !== 75 ? tA.activity : `${tA.activity}: ${tripAccomodation[dayIndexSelected].nom_hebergement}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </CssGrid>
              <CssGrid item key={`phone-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item">
                <Input
                  className="input-gray"
                  id={`phone-${key}`}
                  name="reservation-phone"
                  label="phone"
                  type="text"
                  multiline
                  value={tA.id_activity !== 75 ? tA.phone : tripAccomodation[dayIndexSelected].phone}
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </CssGrid>
              <CssGrid item key={`email-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item-read">
                <Input
                  className="input-gray"
                  id={`email-${key}`}
                  name="reservation-email"
                  label="email"
                  type="text"
                  multiline
                  value={tA.id_activity !== 75 ? tA.email : tripAccomodation[dayIndexSelected].email}
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </CssGrid>
              {tA.id_activity === 75
                ? (
                  <CssGrid item key={`room-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item">
                    <Input
                      className="input-gray"
                      id={`room-${key}`}
                      name="room"
                      label="room"
                      type="text"
                      multiline
                      value={roomTypeForDay()}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </CssGrid>
                )
                : ''}
              <CssGrid item key={`priceAdult-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item">
                <Input
                  className="input-gray"
                  id={`priceAdult-${key}`}
                  name="reservation-price"
                  label={tA.id_activity !== 75 ? 'price adult' : 'price room'}
                  type="text"
                  multiline
                  value={tA.id_activity !== 75 ? `${tA[priceSeasonNameAdult]} ${tA.currency}` : `${PriceRoomCalculated}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  error={tA.id_activity === 75 ? (!_.isEmpty(tripAccomodation[dayIndexSelected].date_expiration_price) && moment(tripAccomodation[dayIndexSelected].date_expiration_price) < moment(datejour))
                    : !_.isEmpty(tA.date_expiration_price) && moment(tA.date_expiration_price) < moment(datejour)}

                />

              </CssGrid>
              {tA.id_activity !== 75
                ? (
                  <CssGrid item key={`priceChild-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item">
                    <Input
                      className="input-gray"
                      id={`priceChild-${key}`}
                      name="reservation-price"
                      label={tA.id_activity !== 75 ? 'price child' : ''}
                      type="text"
                      multiline
                      value={tA.id_activity !== 75 ? `${tA[priceSeasonNameChild]} ${tA.currency}` : ''}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      variant="outlined"
                      error={!_.isEmpty(tA.date_expiration_price) && moment(tA.date_expiration_price) < moment(datejour)}
                    />
                  </CssGrid>
                ) : ''}
              {tA.id_activity !== 75
                ? (
                  <CssGrid item key={`priceSenior-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item">
                    <Input
                      className="input-gray"
                      id={`priceSenior-${key}`}
                      name="reservation-price"
                      label={tA.id_activity !== 75 ? 'price adult A' : ''}
                      type="text"
                      multiline
                      value={tA.id_activity !== 75 ? `${tA[priceSeasonNameSenior]} ${tA.currency}` : ''}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      variant="outlined"
                      error={!_.isEmpty(tA.date_expiration_price) && moment(tA.date_expiration_price) < moment(datejour)}
                    />
                  </CssGrid>
                ) : ''}
              <CssGrid item key={`comment-${tA.id_act_cir}`} md={4} xs={12} className="reservation-item">
                <Input
                  id={`comment-${key}`}
                  name="comment"
                  label="comment"
                  type="text"
                  multiline
                  onChange={e => handleChangeComment(key, dayIndexSelected, e)}
                  value={!_.isEmpty(tA.comment) && tA.comment ? tA.comment : ''}
                  margin="normal"
                  variant="outlined"
                />
              </CssGrid>
              <CssGrid item key={`check-${tA.id_act_cir}`} md={1} xs={12} className="reservation-item-checkbox" onClick={() => handleCheck(key, dayIndexSelected)}>
                {tA.activityCheck
                  ? (
                    <img className="imgChecked" src={checked} srcSet={`${checked2x} 2x, ${checked3x} 3x`} alt="Checked" />
                  )
                  : (
                    <img className="imgChecked" src={unchecked} srcSet={`${unchecked2x} 2x, ${unchecked3x} 3x`} alt="Checked" />
                  )
                }
              </CssGrid>

            </CssGrid>
          )
        })}
      </div>
    </div>
  );
}

ReservationListActivity.propTypes = {
  dayIndexSelected: PropTypes.number,
  nbOfDays: PropTypes.number,
  tripContent: PropTypes.array,
  tripAllDays: PropTypes.array,
  tripActivities: PropTypes.object,
  roomList: PropTypes.array,
  tripAccomodation: PropTypes.array,
  tripDetails: PropTypes.array,
  resetValidation: PropTypes.func.isRequired,
  changeTripContent: PropTypes.func.isRequired,
  changeActivityCheck: PropTypes.func.isRequired,
  changeActivityComment: PropTypes.func.isRequired,
  seasonByActivityAll: PropTypes.array,
  tripRoom: PropTypes.array,
  tripSeasonByAccomodation: PropTypes.array,
};

ReservationListActivity.defaultProps = {
  tripContent: [],
  dayIndexSelected: null,
  seasonByActivityAll: [],
  tripRoom: [],
  tripSeasonByAccomodation: [],
  tripDetails: [],
  tripAccomodation: [],
  tripAllDays: [],
  nbOfDays: 1,
  roomList: [],
  tripActivities: {},
};
