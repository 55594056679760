/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import {
  Input,
  ModalComponent,
  ModalActivity,
  ActionButton,
} from '..'


class DayLineBillingRoute extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    tripActivities: PropTypes.object,
    tripDetails: PropTypes.object,
    tripContent: PropTypes.array,
    validateError: PropTypes.bool,
    activityList: PropTypes.array,
    updateItemsBilling: PropTypes.func,
    locationList: PropTypes.array,
    putTripDefaultActivities: PropTypes.object.isRequired,
    putTripActivity: PropTypes.object.isRequired,
    putActivityContent: PropTypes.object,
    deleteTripActivities: PropTypes.object.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    PUT_TRIP_DEFAULT_ACTIVITIES: PropTypes.func.isRequired,
    RESET_PUT_TRIP_DEFAULT_ACTIVITIES: PropTypes.func.isRequired,
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    PUT_TRIP_ACTIVITY: PropTypes.func.isRequired,
    PUT_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    RESET_PUT_TRIP_ACTIVITY: PropTypes.func.isRequired,
    RESET_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    isModalOpenInChildrenBilling: PropTypes.func.isRequired,
    RESET_DELETE_TRIP_ACTIVITIES: PropTypes.func.isRequired,
  }

  static defaultProps = {
    item: {},
    index: 0,
    validateError: false,
    activityList: [],
    tripActivities: {},
    tripContent: [],
    putActivityContent: {},
    locationList: [],
    tripDetails: {},
    updateItemsBilling: () => {},
  }

  constructor(props) {
    super(props);
    this.displayDescritption = this.displayDescritption.bind(this)
    this.displayAutocompleteActivity = this.displayAutocompleteActivity.bind(this)
    this.handleActivityDayChange = this.handleActivityDayChange.bind(this)
    this.saveActivityPersonalised = this.saveActivityPersonalised.bind(this)
    this.loadResponse = this.loadResponse.bind(this)
    this.resetStore = this.resetStore.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      item: {},
      message: '',
      messageSuccess: false,
      isModalOpen: false,
      isModalOpen2: false,
      putActivity: '',
      idActivitySelected: null,
      activityType: '',
    }
  }

  componentDidMount() {
    // const { userId } = this.state
    const {
      item,
    } = this.props
    this.setState({
      item,
    })
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const { item: itemOld } = prevState;
    const { item } = nextProps;

    if (!_.isEqual(item, itemOld)) {
      return {
        item,
      };
    }
    return null; // Aucune mise à jour d'état
  }

  componentDidUpdate() {
    const { token, userId } = this.state
    const {
      item,
      putTripDefaultActivities,
      putTripActivity,
      putActivityContent,
      deleteTripActivities,
      GET_ACTIVITY_LIST,
    } = this.props
    const dayIndex = item.id - 1
    if (!_.isEmpty(putTripDefaultActivities) && 'success' in putTripDefaultActivities) {
      if (putTripDefaultActivities.data && putTripDefaultActivities.data.dayIndex === dayIndex) {
        this.loadResponse(putTripDefaultActivities)
        this.resetStore()
      }
    }

    if (putTripActivity && putTripActivity.success) {
      if (putTripActivity.data.dayIndex === dayIndex) {
        this.loadResponse(putTripActivity)
        this.resetStore()
      }
    }
    if (putActivityContent && putActivityContent.success) {
      GET_ACTIVITY_LIST(userId)
      this.loadResponse(putActivityContent)
      this.resetStore()
    }

    if (!_.isEmpty(deleteTripActivities) && 'success' in deleteTripActivities) {
      if (deleteTripActivities.data.dayIndex === dayIndex) {
        this.loadResponse(deleteTripActivities)
        this.resetStore()
      }
    }
  }

  loadResponse = (response) => {
    const {
      RESET_PUT_TRIP_DEFAULT_ACTIVITIES,
      RESET_DELETE_TRIP_ACTIVITIES,
      RESET_ACTIVITY_CONTENT,
      RESET_PUT_TRIP_ACTIVITY,
    } = this.props
    RESET_PUT_TRIP_DEFAULT_ACTIVITIES()
    RESET_PUT_TRIP_ACTIVITY()
    RESET_DELETE_TRIP_ACTIVITIES()
    RESET_ACTIVITY_CONTENT()
    this.setState({
      message: response.message,
      messageSuccess: response.success,
    })
  }

  resetStore = () => {
    const {
      tripDetails,
      GET_TRIP_ACTIVITIES,
    } = this.props
    const { token } = this.state
    GET_TRIP_ACTIVITIES(token, tripDetails.id_circuit)
    setTimeout(() => {
      this.setState({
        message: '',
        messageSuccess: false,
      })
    }, 1000);
  }

  handleActivityDayChange = (value, reason, detail) => {
    const {
      token,
      item,
    } = this.state
    const {
      tripDetails,
      tripActivities,
      PUT_TRIP_ACTIVITY,
      DELETE_TRIP_ACTIVITIES,
      isModalOpenInChildrenBilling,
    } = this.props

    // const tabArrayId = item.day_activity ? item.day_activity.split(',') : []
    const dayIndex = item.id - 1
    let tabArrayIdAct = []
    if (!_.isEmpty(tripActivities.index)) {
      tabArrayIdAct = tripActivities.index.map(act => act.id_activity)
    }

    // Verifier si l'activité n'est pas deja enregistre dans la liste
    const isAlreadyActivity = detail && detail.option && !_.isEmpty(tabArrayIdAct) ? tabArrayIdAct.includes(detail.option.id_activity) : false;
    if (isAlreadyActivity && reason === 'select-option') reason = 'no-option'
    let nbActivities = 1

    switch (reason) {
      case 'clear':
        nbActivities = tripActivities[dayIndex] ? tripActivities[dayIndex].length : 1
        DELETE_TRIP_ACTIVITIES(token, tripDetails.id_circuit, item.id_journee, dayIndex, null, nbActivities)
        break;
      case 'remove-option':
        DELETE_TRIP_ACTIVITIES(token, tripDetails.id_circuit, item.id_journee, dayIndex, detail.option.id_activity, nbActivities)
        break;
      case 'select-option':
        this.setState({
          isModalOpen: true,
          putActivity: 'add-activity',
          idActivitySelected: detail.option.id_activity,
          activityType: detail.option.activity_type,
        })
        isModalOpenInChildrenBilling(true)

        break;
      default:
        break;
    }
    return true
  }

  // ***************************
  // update items (parent)
  callback = () => {
    // TODO UPDATE Journee
    // item.id_jounee -> item.day_activity
    const { updateItemsBilling } = this.props
    const { item } = this.state
    updateItemsBilling(item)
  }

  displayAutocompleteActivity = (field, list) => {
    const {
      item,
      validateError,
    } = this.state
    const {
      tripActivities,
      tripDetails,
      locationList,
    } = this.props
    console.log(tripActivities[dayIndex])
    const dayIndex = item.id - 1
    const filteredLieuGrand = locationList.filter(ll => ll.id_lieu === item.id_start);
    const listFilteredByLocation = list.filter(aL => ((aL.active !== '2' && aL.id_location === item.id_end) || (!_.isEmpty(filteredLieuGrand) && aL.active !== '2' && aL.id_location_grand && aL.id_location_grand === filteredLieuGrand[0].id_lieu_grand) || (aL.active !== '2' && aL.id_location === item.id_start) || (aL.universel === 1) || (aL.id_location === item.id_start && aL.id_circuit === tripDetails.id_circuit)))
    const activities = tripActivities[dayIndex] ? tripActivities[dayIndex].filter(TAS => TAS.id_activity !== 75) : [];
    const dayActivities = list.filter(al => activities.map(ac => ac.id_activity).includes(al.id_activity))
    const participant = dayActivities.map(da => tripActivities[dayIndex].filter(ta => ta.id_activity === da.id_activity)[0])
    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    return (
      <Autocomplete
        id={`${field}-field`}
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={dayActivities}
        onChange={(event, newValue, reason, detail) => {
          this.handleActivityDayChange(newValue, reason, detail);
        }}
        options={listFilteredByLocation}
        getOptionLabel={option => `${option.activity} --- Location: ${option.location} --- Province: ${option.locationGrand} --- Supplier: ${option.supplier}`}
        renderTags={(value, getTagProps) => value.map((option, index) => (option.activity_type === 'individual'
          ? (
            <Chip
              title={` 
                       Activity Name: ${option.activity}\n
                       Nb Adult A: ${participant[index].nb_senior}\n
                       Nb Adult B: ${participant[index].nb_adult}\n
                       Nb Children: ${participant[index].nb_child}\n
                       Activity type: ${option.activity_type}\n                        
                       price adult A season: ${option.price_seniorpeakseason}\n 
                       Price adult A high season: ${option.price_seniorhighseason}\n 
                       Price adult A low season: ${option.price_seniorlowseason}\n 
                       Price adult B standard season: ${option.price_adultpeakseason}\n 
                       Price Adult B high season: ${option.price_adulthighseason}\n 
                       Price adult B low season: ${option.price_adultlowseason}\n 
                       price child standard season: ${option.price_childpeakseason}\n 
                       Price child high season: ${option.price_childhighseason}\n 
                       Price chidl low season: ${option.price_childlowseason}\n 
                       date expiration price: ${moment(option.date_expiration_price).format('YYYY-MM-DD')}\n 
                       Currency: ${option.currency}
                       Activity theme: ${option.activity_theme}\n
          `}
              style={FondDefautIndividual}
              variant="outlined"
              label={`${option.activity.length > 30 ? `${option.activity.substring(0, 30)}...` : option.activity}` || ''}
              {...getTagProps({ index })}
            />
          )
          : (
            <Chip
              title={` 
              Activity Name: ${option.activity}\n
              Nb: ${participant[index].nb_adult}\n
              Activity type: ${option.activity_type}\n
              Activity theme: ${option.activity_theme}\n 
              Price standard season: ${option.price_adultpeakseason}\n 
              Price high season: ${option.price_adulthighseason}\n 
              Price low season: ${option.price_adultlowseason}\n 
              date expiration price: ${moment(option.date_expiration_price).format('YYYY-MM-DD')}\n 
              Currency: ${option.currency}
            `}
              style={FondDefautGroup}
              variant="outlined"
              label={`${option.activity.length > 30 ? `${option.activity.substring(0, 30)}...` : option.activity}` || ''}
              {...getTagProps({ index })}
            />
          )))
        }
        renderInput={params => (
          <Input
            {...params}
            margin="normal"
            placeholder={`Select a ${field}`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }


  // Display description wwhen click on Number line
  displayDescritption = (stripe) => {
    const {
      item,
      message,
      messageSuccess,
      isModalOpen,
      isModalOpen2,
      activityType,
    } = this.state
    const {
      activityList,
      tripDetails,
      tripActivities,
      tripContent,
    } = this.props
    const HtmlTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 520,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);
    const activities = tripActivities[item.id - 1] || [];
    let backgroundColor = ''
    if (!_.isEmpty(message)) {
      backgroundColor = messageSuccess ? 'valid' : 'error';
    }
    const desc = `desc_jour_${item.id}`;
    const defaultSenior = tripDetails.nb_senior && !isNaN(parseInt(tripDetails.nb_senior, 10)) ? parseInt(tripDetails.nb_senior, 10) : 0
    const defaultAdult = tripDetails.nb_adulte && !isNaN(parseInt(tripDetails.nb_adulte, 10)) ? parseInt(tripDetails.nb_adulte, 10) : 1
    const defaultChildren = tripDetails.nb_enfant && !isNaN(parseInt(tripDetails.nb_enfant, 10)) ? parseInt(tripDetails.nb_enfant, 10) : 0
    const inputValue = activityType === 'individual' ? (
      [
        'adult',
        'children',
      ]
    ) : (
      [
        'nb',
      ]
    )
    return (
      <div className="description">
        <Grid
          container
          spacing={2}
          className="dayLineBilling"
        >
          <Grid item xs={8}>
            {!_.isEmpty(item) && this.displayAutocompleteActivity('day_activity', activityList)}
          </Grid>
          <Grid item xs={2}>
            <ActionButton
              label="Load"
              onClick={e => this.getDefaultActivities(e)}
              title="Load default activities for this day"
              disabled={!_.isEmpty(activities) && (activities.length !== 1 || activities[0].id_activity !== 75)}
            />
          </Grid>
          <Grid item xs={2}>
            <ActionButton
              label="Create"
              title="Create a unique activity for this day"
              onClick={this.handleOpenModal2}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item className={`msgRequestSmall ${backgroundColor}`}>
            {message}
          </Grid>
        </Grid>
        <ModalComponent
          isModalOpen={isModalOpen}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={participant => this.updateTripDayActivity(participant)}
          title="Enter number."
          disabled={false}
          modalContent="trip_activity"
          type="number"
          inputValue={inputValue}
          defaultSenior={defaultSenior}
          defaultAdult={defaultAdult}
          defaultChildren={defaultChildren}
        />
        <ModalActivity
          isModalOpen={isModalOpen2}
          onClose={this.handleClose}
          onResponseNo={this.handleClose}
          onResponseYes={activityContent => this.saveActivityPersonalised(activityContent)}
          title="Create the activity"
          modalContent="activity_personalised"
        />
      </div>
    )
  }

  getDefaultActivities = (e) => {
    e.preventDefault()
    const {
      item,
      token,
    } = this.state
    const {
      tripDetails,
      PUT_TRIP_DEFAULT_ACTIVITIES,
    } = this.props
    const dayIndex = item.id - 1
    const nbSenior = tripDetails.nb_senior && !isNaN(parseInt(tripDetails.nb_senior, 10)) && parseInt(tripDetails.nb_senior, 10)
    const nbAdult = tripDetails.nb_adulte && !isNaN(parseInt(tripDetails.nb_adulte, 10)) && parseInt(tripDetails.nb_adulte, 10)
    const nbChild = tripDetails.nb_enfant && !isNaN(parseInt(tripDetails.nb_enfant, 10)) && parseInt(tripDetails.nb_enfant, 10)

    // check nombre de personne adult et enfant
    if (!tripDetails.nb_senior && !tripDetails.nb_adulte && !tripDetails.nb_enfant) {
      this.setState({
        isModalOpen: true,
        putActivity: 'default-activities',
      })
    } else {
      PUT_TRIP_DEFAULT_ACTIVITIES(token, tripDetails.id_circuit, item.id_journee, dayIndex, nbSenior, nbAdult, nbChild)
    }
  }

  handleClose = () => {
    const { scrollY } = window;
    this.setState({
      isModalOpen: false,
      isModalOpen2: false,
    })
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }

  handleOpenModal2 = () => {
    this.setState({
      isModalOpen2: true,
    })
  }

  updateTripDayActivity = (participant) => {
    const {
      token,
      putActivity,
      idActivitySelected,
      activityType,
      item,
    } = this.state
    const {
      tripDetails,
      PUT_TRIP_DEFAULT_ACTIVITIES,
      PUT_TRIP_ACTIVITY,
    } = this.props
    let nbSenior = null
    let nbAdult = null
    let nbChild = null
    if (activityType === 'individual') {
      nbSenior = participant && participant.senior && participant.senior
      nbAdult = participant && participant.adult && participant.adult
      nbChild = participant && participant.children && participant.children
    } else {
      nbAdult = participant && participant.nb && participant.nb
    }
    const dayIndex = item.id - 1
    if (putActivity === 'default-activities') {
      PUT_TRIP_DEFAULT_ACTIVITIES(token, tripDetails.id_circuit, item.id_journee, dayIndex, nbSenior, nbAdult, nbChild)
    } else if (putActivity === 'add-activity') {
      PUT_TRIP_ACTIVITY(token, tripDetails.id_circuit, item.id_journee, dayIndex, idActivitySelected, nbSenior, nbAdult, nbChild)
    }
    this.setState({
      isModalOpen: false,
      putActivity: '',
      idActivitySelected: null,
    })
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
  }


  saveActivityPersonalised = (activityContent) => {
    const {
      token,
      item,
    } = this.state
    const {
      PUT_ACTIVITY_CONTENT,
      tripDetails,
    } = this.props
    activityContent.id_circuit = tripDetails.id_circuit
    activityContent.active = 2
    activityContent.id_lieu = item.id_start
    PUT_ACTIVITY_CONTENT(token, activityContent, 'create')
    this.setState({
      isModalOpen2: false,
    })
  }

  // ***************************
  render() {
    const {
      index,
    } = this.props
    // background striped line by line
    const stripe = index % 2 === 0 ? 'stripe' : null
    return (
      <div className={`dayLine ${stripe}`}>

        {/* </div> */}
        {this.displayDescritption(stripe)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activityList: state.activityReducer.activityList,
  putTripDefaultActivities: state.billingReducer.putTripDefaultActivities,
  putActivityContent: state.activityReducer.putActivityContent,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  putTripActivity: state.billingReducer.putTripActivity,
  tripContent: state.api.tripContent,
  deleteTripActivities: state.billingReducer.deleteTripActivities,
})
const mapDispatchToProps = dispatch => ({
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),
  PUT_TRIP_DEFAULT_ACTIVITIES: (token, idCircuit, idJournee, dayIndex, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_DEFAULT_ACTIVITIES',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    nbSenior,
    nbAdult,
    nbChild,
  }),
  RESET_PUT_TRIP_DEFAULT_ACTIVITIES: () => dispatch({
    type: 'RESET_PUT_TRIP_DEFAULT_ACTIVITIES',
  }),
  PUT_TRIP_ACTIVITY: (token, idCircuit, idJournee, dayIndex, idActivity, nbSenior, nbAdult, nbChild) => dispatch({
    type: 'PUT_TRIP_ACTIVITY',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbSenior,
    nbAdult,
    nbChild,
  }),
  RESET_PUT_TRIP_ACTIVITY: () => dispatch({
    type: 'RESET_PUT_TRIP_ACTIVITY',
  }),
  DELETE_TRIP_ACTIVITIES: (token, idCircuit, idJournee, dayIndex, idActivity, nbActivities) => dispatch({
    type: 'DELETE_TRIP_ACTIVITIES',
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbActivities,
  }),
  PUT_ACTIVITY_CONTENT: (token, activityContent, action) => dispatch({
    type: 'PUT_ACTIVITY_CONTENT',
    token,
    activityContent,
    action,
  }),
  RESET_ACTIVITY_CONTENT: () => dispatch({
    type: 'RESET_ACTIVITY_CONTENT',
  }),
  RESET_DELETE_TRIP_ACTIVITIES: () => dispatch({
    type: 'RESET_DELETE_TRIP_ACTIVITIES',
  }),
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DayLineBillingRoute))
