import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import {
  TextField,
  MenuItem,
} from '@material-ui/core'
import {
  Input,
  ActionButton,
} from '..'

class ModalComponent extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onResponseNo: PropTypes.func.isRequired,
    onResponseNoAll: PropTypes.func.isRequired,
    onResponseYes: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    item: PropTypes.object,
    type: PropTypes.string,
    dateDepart: PropTypes.object,
    longueurVoyage: PropTypes.number,
    title: PropTypes.string,
    defaultSenior: PropTypes.number,
    defaultAdult: PropTypes.number,
    defaultChildren: PropTypes.number,
    warning: PropTypes.string,
    modalContent: PropTypes.string,
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    simpleAction: PropTypes.bool,
    tripleAction: PropTypes.bool,
    additionalColumnPrice: PropTypes.object,
  }

  static defaultProps = {
    item: {},
    type: 'text',
    title: '',
    warning: '',
    modalContent: '',
    dateDepart: '',
    inputValue: '',
    defaultSenior: 0,
    defaultAdult: 0,
    defaultChildren: 0,
    longueurVoyage: 1,
    disabled: true,
    simpleAction: false,
    tripleAction: false,
    additionalColumnPrice: {},
  }

  constructor(props) {
    super(props);
    this.selectContent = this.selectContent.bind(this)
    this.displayCreateItem = this.displayCreateItem.bind(this)
    this.displaySelectItem = this.displaySelectItem.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onResponseNo = this.onResponseNo.bind(this)
    this.onResponseYes = this.onResponseYes.bind(this)
    this.displayListDate = this.displayListDate.bind(this)
    this.state = {
      item: {},
      type: ['yes', 'no'],
      dateTransport: {},
    };
  }


  componentDidMount() {
    const {
      modalContent,
      inputValue,
      item,
      defaultSenior,
      defaultAdult,
      defaultChildren,
      dateDepart,
    } = this.props;

    this.setState({
      item: {
        ...item,
        [modalContent]: inputValue,
        senior: defaultSenior,
        adult: defaultAdult,
        children: defaultChildren,
        version: defaultAdult,
        date: 1,
        nb: 1,
      },
      dateTransport: dateDepart,
    });
  }

  componentWillReceiveProps(nextprops) {
    const {
      modalContent,
      inputValue,
      item,
      type,
      defaultSenior,
      defaultAdult,
      defaultChildren,
      dateDepart,
    } = nextprops
    switch (type) {
      case 'version':
        this.setState({
          type: ['update', 'create'],
        })
        break;
      case 'trip':
      case 'location':
      case 'pension':
      case 'room':
      case 'accomodation_type':
      case 'extension':
      case 'day':
      case 'accomodation':
      case 'customer':
      case 'additional_column':
      case 'delete_additional_column':
      case 'price':
      case 'number_room':
      case 'number_room_opt1':
      case 'number_room_opt2':
      case 'activity':
      case 'trip_activity':
      case 'dateToUpdate':
        this.setState({
          type: ['validate', 'cancel'],
        })
        break;
      case 'deleteActivity':
        this.setState({
          type: ['ALL', 'ONE', 'cancel'],
        })
        break;
      default:
        this.setState({
          type: ['yes', 'no'],
        })
        break;
    }
    if (inputValue !== item.modalContent) {
      this.setState({
        item: {
          ...item,
          [modalContent]: inputValue,
          senior: defaultSenior,
          adult: defaultAdult,
          children: defaultChildren,
          nb: 1,
        },
        dateTransport: dateDepart,
      })
    }
  }

  // ********************
  // Modal
  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  // ******************************
  // Action on input
  handleChange = field => (event) => {
    const { item } = this.state
    const { dateDepart } = this.props

    if (field === 'dateTransport') {
      const dateFinObj = moment(event.target.value, 'YYYY-MM-DD');
      const nombreDeJours = dateFinObj.diff(dateDepart, 'days') + 1;

      this.setState({
        item: {
          ...item,
          date: nombreDeJours,
        },
        dateTransport: event.target.value,
      })
    } else {
      this.setState({
        item: {
          ...item,
          [field]: event.target.value,
        },
      })
    }
  }

  // **************************
  // display specific element
  displayCreateItem = (field) => {
    const { item } = this.state
    const { disabled, type } = this.props
    const widthField = item[field] ? item[field].length + 10 : '0'
    return (
      <TextField
        id={field}
        label={_.capitalize(field)}
        disabled={disabled}
        type={type || 'text'}
        value={item[field]}
        onChange={this.handleChange(field)}
        margin="normal"
        variant="outlined"
        style={{ minWidth: `${widthField}ch` }}
        inputProps={{ min: '1' }}
      />
    )
  }


  displaySelectItem = (field) => {
    const {
      item,
    } = this.state
    const {
      additionalColumnPrice,
    } = this.props
    const additionalColumnList = !_.isEmpty(additionalColumnPrice) ? Object.values(additionalColumnPrice).filter(v => typeof v === 'string') : []
    const widthField = item[field] ? item[field].length + 10 : '0'

    return (
      <Input
        id="additionalColumnList"
        label="Column name"
        select
        value={item[field]}
        onChange={this.handleChange(field)}
        margin="normal"
        variant="outlined"
        style={{ minWidth: `${widthField}ch` }}
      >
        {_.map(additionalColumnList, (column, k) => <MenuItem key={k} value={column}>{column}</MenuItem>)}
      </Input>
    )
  }

  displayListDate = (list) => {
    const { item, dateTransport } = this.state
    const { disabled, dateDepart, longueurVoyage } = this.props
    const dateFin = moment(dateDepart, 'YYYY-MM-DD').add(longueurVoyage - 1, 'd').format('YYYY-MM-DD');
    return (
      item[list] && item[list].map((l) => {
        let labelDisp = ''
        switch (l) {
          case 'senior':
            labelDisp = 'Adult A';
            break;
          case 'adult':
            labelDisp = 'Adult B';
            break;
          default:
            labelDisp = l;
            break;
        }
        return (

          l === 'date' ? (
            <Input
              InputProps={{
                inputProps: {
                  min: dateDepart,
                  max: dateFin,
                },
              }}
              id="resa"
              name="resa"
              label="Until"
              type="date"
              margin="normal"
              variant="outlined"
              value={dateTransport}
              onChange={this.handleChange('dateTransport')}
            />

          ) : (
            <TextField
              id={l}
              key={l}
              label={_.capitalize(labelDisp)}
              value={item[l]}
              disabled={disabled}
              type="number"
           // value={}
              onChange={this.handleChange(l)}
              margin="normal"
              variant="outlined"
           // style={{ minWidth: `${widthField}ch` }}
              inputProps={{ min: '1' }}
            />
          )
        )
      })
    )
  }

  displayListItem = (list) => {
    const { item } = this.state
    const { disabled, type } = this.props

    return (
      item[list] && item[list].map((l) => {
        let labelDisp = ''
        switch (l) {
          case 'senior':
            labelDisp = 'Adult A';
            break;
          case 'adult':
            labelDisp = 'Adult B';
            break;
          default:
            labelDisp = l;
            break;
        }
        return (
          <TextField
            id={l}
            key={l}
            label={_.capitalize(labelDisp)}
            value={item[l]}
            disabled={disabled}
            type={type || 'text'}
          // value={}
            onChange={this.handleChange(l)}
            margin="normal"
            variant="outlined"
          // style={{ minWidth: `${widthField}ch` }}
            inputProps={{ min: '1' }}
          />
        )
      })
    )
  }

  selectContent = (field) => {
    switch (field) {
      case 'title':
      case 'start':
      case 'end':
      case 'day':
      case 'accomodation':
      case 'room':
      case 'pension':
      case 'accomodation_type':
      case 'location':
      case 'extension':
      case 'customer':
      case 'additional_column':
      case 'number_room':
      case 'price':
      case 'number_room_opt1':
      case 'number_room_opt2':
      case 'activity':
        return this.displayCreateItem(field)
      case 'delete_additional_column':
        return this.displaySelectItem(field)
      case 'trip_activity':
        return this.displayListItem(field)
      case 'dateToUpdate':
        return this.displayListDate(field)
      default:
        break;
    }
    return null
  }

  // **********************
  // Modal response
  onResponseNo = () => {
    const { onResponseNo } = this.props
    onResponseNo()
  }

  onResponseNoAll = () => {
    const { onResponseNoAll } = this.props
    onResponseNoAll()
  }

  onResponseYes = () => {
    const { item } = this.state
    const { onResponseYes } = this.props
    onResponseYes(item)
  }

  // **************************
  // Render
  render() {
    const {
      isModalOpen,
      title,
      warning,
      modalContent,
      simpleAction,
      tripleAction,
    } = this.props
    const {
      type,
    } = this.state
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isModalOpen}
        onClose={this.handleClose}
      >
        <div className="modal">
          <h2 id="simple-modal-title">{title}</h2>
          {modalContent ? this.selectContent(modalContent) : null}
          {warning ? <p style={{ color: 'red', margin: '0, 0, 3px, 0' }}>{warning}</p> : null}
          <div className="modalActions">
            <ActionButton
              label={type[0]}
              onClick={() => this.onResponseYes()}
              color="reverse"
            />
            {simpleAction
              ? null
              : (
                <ActionButton
                  label={type[1]}
                  onClick={() => this.onResponseNo()}
                  color="reverse"
                />
              )
            }
            {!tripleAction
              ? null
              : (
                <ActionButton
                  label={type[2]}
                  onClick={() => this.onResponseNoAll()}
                  color="reverse"
                />
              )
            }
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalComponent
