
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _ from 'lodash'
import MaterialTable from '@material-table/core';
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import tableIcons from '../../../components/utils/icons/icons'
import {
  individualColumns,
} from '../configComponents/individualColumns'
import {
  groupColumns,
} from '../configComponents/groupColumns'
import {
  accoColumns,
} from '../configComponents/accoColumns'
import {
  ModalComponent,
} from '../../../components'

export default function TableService(props) {
  const {
    activityListByCircuit,
    tripAccomodation,
    tripSeasonByAccomodation,
    seasonByActivityAll,
    tripRoom,
    changePrice,
    deleteActivity,
    tripAllDays,
    tripDetails,
    roomList,
    tripContent,
    dayIndexSelected,
  } = props
  const TabIncJour = tripAllDays ? tripAllDays.map(item => (item.day_duration)) : []
  const IncDay = _.sum(TabIncJour.slice(0, dayIndexSelected))
  const [isModalOpen, setisModalOpen] = useState(false)
  const [isModalOpenDelete, setisModalOpenDelete] = useState(false)
  const [priceChange, setPriceChange] = useState({
    activityType: '', valuePriceSenior: 0, valuePriceAdult: 0, valuePriceChild: 0, id_act_cir: 0,
  })
  const [actToDelete, setActToDelete] = useState(0)

  const handlePriceChange = (id_act_cir, valueSenior, valueAdult, valueChild, activityType) => {
    setisModalOpen(true)
    setPriceChange({
      valuePriceSenior: valueSenior, valuePriceAdult: valueAdult, valuePriceChild: valueChild, id_act_cir: id_act_cir, activityType: activityType,
    })
  };


  const handleDeleteActivity = (id_act_cir) => {
    setisModalOpenDelete(true)
    setActToDelete({
      id_act_cir,
    })
  };


  const seasonByAccomodation = tripSeasonByAccomodation.filter(c => c.id_hebergement === tripAccomodation[dayIndexSelected].id_hebergement)
  let seasonName = ''
  let PriceRoomCalculated = 0
  let PriceRoomCalculatedForDisplay = ''

  const roomTypeArray = tripContent ? tripContent[0].type_chambre.split(',') : []
  const roomsNumberArray = tripContent && tripContent[0].nb_chambre ? tripContent[0].nb_chambre.split(',') : null
  const roomTypeForDay = () => {
    const roomsTypeForDay = roomTypeArray[dayIndexSelected] && roomTypeArray[dayIndexSelected] !== '1' ? roomTypeArray[dayIndexSelected].split('-') : []
    const roomsNbForDay = roomsNumberArray && roomsNumberArray[dayIndexSelected] !== 'null' ? roomsNumberArray[dayIndexSelected].split('-') : []
    const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
    const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
    return label.join('')
  }
  const PriceRoom = tripRoom[dayIndexSelected]
  const roomsNbForDay = roomsNumberArray && roomsNumberArray[dayIndexSelected] !== 'null' ? roomsNumberArray[dayIndexSelected].split('-') : []
  let datejour1 = tripDetails[0].number_day_departure ? tripDetails[0].number_day_departure + IncDay : ''
  const annee = _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(dayIndexSelected, 'days').format('YYYY'))

  const ily = ((annee % 4 === 0) && (annee % 100 !== 0)) || (annee % 400 === 0);
  if (ily && (datejour1 > 59)) (datejour1 -= 1)

  if (!_.isEmpty(seasonByAccomodation)) {
    seasonByAccomodation.map((sBA) => {
      if (datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
        seasonName = `price_${sBA.period_name}`
      }
      return null
    })
  }
  if (PriceRoom) {
    PriceRoom.map((PR, index2) => {
      if (PR[`${seasonName}`] && PR[seasonName] !== 0) {
        PriceRoomCalculated += (PR[seasonName] * roomsNbForDay[index2]) * (tripAllDays[dayIndexSelected].day_duration ? tripAllDays[dayIndexSelected].day_duration : 1)
        PriceRoomCalculatedForDisplay = `${PriceRoomCalculatedForDisplay} + ${(PR[`${seasonName}`] * roomsNbForDay[index2] * (tripAllDays[dayIndexSelected].day_duration ? tripAllDays[dayIndexSelected].day_duration : 1))}`
      } else {
        PriceRoomCalculated += (PR.price_peakseason * roomsNbForDay[index2]) * (tripAllDays[dayIndexSelected].day_duration ? tripAllDays[dayIndexSelected].day_duration : 1)
        PriceRoomCalculatedForDisplay = `${PriceRoomCalculatedForDisplay} + ${(PR.price_peakseason * roomsNbForDay[index2] * (tripAllDays[dayIndexSelected].day_duration ? tripAllDays[dayIndexSelected].day_duration : 1))}`
      }
      return null
    })
  }
  PriceRoomCalculatedForDisplay = PriceRoomCalculatedForDisplay.slice(3);
  const updatedActivityListByCircuit = activityListByCircuit.map((activity) => {
    // Calcul du total des prix non payés
    const roomDetail = activity.id_activity === 75 ? roomTypeForDay() : ''
    const seasonByActivities = seasonByActivityAll.filter(sBA => sBA.id_activity === activity.id_activity)
    let priceSeasonNameAdult = 0
    let priceSeasonNameChild = 0
    let priceSeasonNameSenior = 0
    let isSeasonFound = false;
    if (!_.isEmpty(seasonByActivities)) {
      seasonByActivities.map((sBA) => {
        if (!isSeasonFound && datejour1 >= sBA.number_day_start && datejour1 <= sBA.number_day_end) {
          priceSeasonNameAdult = `price_adult${sBA.period_name}`
          priceSeasonNameChild = `price_child${sBA.period_name}`
          priceSeasonNameSenior = `price_senior${sBA.period_name}`
          isSeasonFound = true;
        } else if (!isSeasonFound) {
          priceSeasonNameAdult = 'price_adultpeakseason'
          priceSeasonNameChild = 'price_childpeakseason'
          priceSeasonNameSenior = 'price_seniorpeakseason'
        }
        return null
      })
    } else {
      priceSeasonNameAdult = 'price_adultpeakseason'
      priceSeasonNameChild = 'price_childpeakseason'
      priceSeasonNameSenior = 'price_seniorpeakseason'
    }
    let priceCalculatedActivityAdult = 0
    if (activity.activity_type === 'individual') {
      priceCalculatedActivityAdult = activity[priceSeasonNameAdult] * activity.nb_adult
    } else {
      priceCalculatedActivityAdult = activity[priceSeasonNameAdult] * (activity.nb_adult ? activity.nb_adult : 1)
    }
    const priceCalculatedAdultSum = activity.id_activity === 75 ? PriceRoomCalculated : priceCalculatedActivityAdult
    const priceCalculatedAdult = activity.id_activity === 75 ? PriceRoomCalculatedForDisplay : priceCalculatedActivityAdult
    const priceCalculatedSenior = activity.id_activity === 75 ? '' : activity[priceSeasonNameSenior] * activity.nb_senior
    const priceCalculatedChild = activity.id_activity === 75 ? '' : activity[priceSeasonNameChild] * activity.nb_child
    const nomService = activity.id_activity === 75 ? activity.nom_hebergement : activity.activity
    const final_price_adult = (activity.final_price != null) ? activity.final_price : priceCalculatedAdultSum
    const final_price_senior = (activity.final_price_senior != null) ? activity.final_price_senior : priceCalculatedSenior
    const final_price_child = (activity.final_price_child != null) ? activity.final_price_child : priceCalculatedChild
    const currencyService = activity.id_activity === 75 ? activity.currency_hebergement : activity.currency


    return {
      ...activity,
      roomDetail,
      priceCalculatedAdult,
      priceCalculatedAdultSum,
      priceCalculatedSenior,
      priceCalculatedChild,
      final_price_adult,
      final_price_child,
      final_price_senior,
      currencyService,
      nomService,
    };
  });
  const handleClose = () => {
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
    setisModalOpen(false)
    setisModalOpenDelete(false)
  };

  const updatePrice = (participant) => {
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
    setisModalOpen(false)
    setPriceChange((prevData) => {
      const newData = {
        ...prevData, valuePriceAdult: participant.adult, valuePriceSenior: participant.senior, valuePriceChild: participant.children,
      };
      changePrice(newData); // Call changePrice with the updated data immediately
      return newData;
    });
  };

  const handleOpenDelete = () => {
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
    setisModalOpenDelete(false)
    deleteActivity(actToDelete)
  }

  const inputValue = priceChange.activityType === 'individual' ? (
    [
      'senior',
      'adult',
      'children',
    ]
  ) : (
    [
      'adult',
    ]
  )
  const updatedActivityListByCircuitAcco = updatedActivityListByCircuit.filter(uALC => uALC.id_activity === 75)
  const updatedActivityListByCircuitGroup = updatedActivityListByCircuit.filter(uALC => uALC.id_activity !== 75).filter(uALC => uALC.activity_type === 'group')
  const updatedActivityListByCircuitIndividual = updatedActivityListByCircuit.filter(uALC => uALC.id_activity !== 75).filter(uALC => uALC.activity_type === 'individual')
  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <div style={{ overflowX: 'auto' }}>
            <MaterialTable
              title="Individual services and activites"
              style={{ marginBottom: '30px' }}
              data={updatedActivityListByCircuitIndividual}
              columns={individualColumns}
              icons={tableIcons}
              options={{
                exportMenu: [{
                  label: 'Export PDF',
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export list'),
                }, {
                  label: 'Export CSV',
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export list'),
                }],
                pageSize: 5,
                responsive: 'simple',
                sorting: false,
                headerStyle: { textAlign: 'left' },
                actionsColumnIndex: -1,
                columnResizable: true,
                doubleHorizontalScroll: true,
                selectableRows: 'single',
                selectableRowsOnClick: true,
                columnsButton: true,
              }}
              actions={[
                {
                  icon: tableIcons.Edit,
                  tooltip: 'change price',
                  onClick: (event, rowData) => {
                    handlePriceChange(rowData.id_act_cir, rowData.final_price_senior, rowData.final_price_adult, rowData.final_price_child, rowData.activity_type)
                  },
                },
                {
                  icon: tableIcons.Delete,
                  tooltip: 'change price',
                  onClick: (event, rowData) => {
                    handleDeleteActivity(rowData.id_act_cir)
                  },
                },

              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ overflowX: 'auto' }}>
            <MaterialTable
              title="Group services and activites"
              style={{ marginBottom: '30px' }}
              data={updatedActivityListByCircuitGroup}
              columns={groupColumns}
              icons={tableIcons}
              options={{
                exportMenu: [{
                  label: 'Export PDF',
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export list'),
                }, {
                  label: 'Export CSV',
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export list'),
                }],
                pageSize: 5,
                responsive: 'simple',
                sorting: false,
                headerStyle: { textAlign: 'left' },
                actionsColumnIndex: -1,
                columnResizable: true,
                doubleHorizontalScroll: true,
                selectableRows: 'single',
                selectableRowsOnClick: true,
                columnsButton: true,
              }}
              actions={[
                {
                  icon: tableIcons.Edit,
                  tooltip: 'change price',
                  onClick: (event, rowData) => {
                    handlePriceChange(rowData.id_act_cir, rowData.final_price_senior, rowData.final_price_adult, rowData.final_price_child, rowData.activity_type)
                  },
                },
                {
                  icon: tableIcons.Delete,
                  tooltip: 'change price',
                  onClick: (event, rowData) => {
                    handleDeleteActivity(rowData.id_act_cir)
                  },
                },

              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ overflowX: 'auto' }}>
            <MaterialTable
              style={{ marginBottom: '30px' }}
              title="Accomodation"
              data={updatedActivityListByCircuitAcco}
              columns={accoColumns}
              icons={tableIcons}
              options={{
                exportMenu: [{
                  label: 'Export PDF',
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export list'),
                }, {
                  label: 'Export CSV',
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export list'),
                }],
                pageSize: 2,
                responsive: 'simple',
                sorting: false,
                headerStyle: { textAlign: 'left' },
                actionsColumnIndex: -1,
                columnResizable: true,
                doubleHorizontalScroll: true,
                selectableRows: 'single',
                selectableRowsOnClick: true,
                columnsButton: true,
              }}
              actions={[
                {
                  icon: tableIcons.Edit,
                  tooltip: 'change price',
                  onClick: (event, rowData) => {
                    handlePriceChange(rowData.id_act_cir, rowData.final_price_senior, rowData.final_price_adult, rowData.final_price_child, rowData.activity_type)
                  },
                },
                {
                  icon: tableIcons.Delete,
                  tooltip: 'change price',
                  onClick: (event, rowData) => {
                    handleDeleteActivity(rowData.id_act_cir)
                  },
                },

              ]}
            />
          </div>
        </Grid>
      </Grid>
      {isModalOpen
      && (
      <ModalComponent
        isModalOpen={isModalOpen}
        onClose={handleClose}
        onResponseNo={handleClose}
        onResponseYes={participant => updatePrice(participant)}
        title="Enter price."
        disabled={false}
        modalContent="trip_activity"
        type="number"
        inputValue={inputValue}
        defaultAdult={priceChange.valuePriceAdult}
        defaultChildren={priceChange.valuePriceChild}
        defaultSenior={priceChange.valuePriceSenior}
      />
      )
}
      {isModalOpenDelete
      && (
        <ModalComponent
          isModalOpen={isModalOpenDelete}
          onClose={handleClose}
          onResponseNo={handleClose}
          onResponseYes={handleOpenDelete}
          title="Are you sure you want to delete this activity?"
        />
      )
}
    </div>
  );
}

TableService.propTypes = {
  dayIndexSelected: PropTypes.number,
  tripContent: PropTypes.array,
  tripAllDays: PropTypes.array,
  roomList: PropTypes.array,
  tripAccomodation: PropTypes.array,
  tripDetails: PropTypes.array,
  tripRoom: PropTypes.array,
  tripSeasonByAccomodation: PropTypes.array,
  activityListByCircuit: PropTypes.array,
  seasonByActivityAll: PropTypes.array,
  changePrice: PropTypes.func.isRequired,
  deleteActivity: PropTypes.func.isRequired,
};

TableService.defaultProps = {
  activityListByCircuit: [],
  tripContent: [],
  dayIndexSelected: null,
  tripRoom: [],
  tripSeasonByAccomodation: [],
  seasonByActivityAll: [],
  tripDetails: [],
  tripAccomodation: [],
  tripAllDays: [],
  roomList: [],
};
