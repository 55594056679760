import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import { renderToStaticMarkup } from 'react-dom/server';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import { CssGrid } from '../../configCSS'

import {
  Template,
  Stepper,
  ActionButton,
  TextEditor,
  Input,
} from '../../components'


// config empty extension
const locationFormatRegExp = new RegExp(' ', 'g')

class TripSite extends PureComponent {
  static propTypes = {
    idTripSelected: PropTypes.number,
    history: PropTypes.object.isRequired,
    GET_SUPPLIERS_LIST: PropTypes.func.isRequired,
    PUT_TRIP_DETAILS_SITE: PropTypes.func.isRequired,
    RESET_TRIP_DETAILS_SITE: PropTypes.func.isRequired,
    GET_TRIP_DETAILS: PropTypes.func.isRequired,
    tripDetails: PropTypes.array,
    locationList: PropTypes.array,
    informationsContent: PropTypes.object,
    locationGrandList: PropTypes.array,
    suppliersList: PropTypes.array,
    activityList: PropTypes.array,
    putTripDetailsSite: PropTypes.object,
  }

  static defaultProps = {
    idTripSelected: null,
    suppliersList: [],
    tripDetails: [],
    locationList: [],
    locationGrandList: [],
    activityList: [],
    informationsContent: {},
    putTripDetailsSite: {},
  }

  constructor(props) {
    super(props);
    this.displayActivityTheme = this.displayActivityTheme.bind(this)
    this.displayAutocompleteActivityTheme = this.displayAutocompleteActivityTheme.bind(this)
    this.getOptionLabelActivityTheme = this.getOptionLabelActivityTheme.bind(this)
    this.onChangeActivityTheme = this.onChangeActivityTheme.bind(this)
    this.displayAutocompleteSupplier = this.displayAutocompleteSupplier.bind(this)
    this.getOptionLabelSupplier = this.getOptionLabelSupplier.bind(this)
    this.filterOptionsSupplier = this.filterOptionsSupplier.bind(this)
    this.displayAutocompleteLocationGrand = this.displayAutocompleteLocationGrand.bind(this)
    this.getOptionLabelLocationGrand = this.getOptionLabelLocationGrand.bind(this)
    this.getOptionLabelLocation = this.getOptionLabelLocation.bind(this)
    this.onChangeLocationGrand = this.onChangeLocationGrand.bind(this)
    this.displayAutocompleteLocation = this.displayAutocompleteLocation.bind(this)
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.filterOptionsLocation = this.filterOptionsLocation.bind(this)
    this.onChangeSupplier = this.onChangeSupplier.bind(this)
    this.displayAutocompleteActivity = this.displayAutocompleteActivity.bind(this)
    this.handleActivityDayChange = this.handleActivityDayChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleDefaultChange = this.handleDefaultChange.bind(this)
    this.validate = this.validate.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isValidated: true,
      validMsg: null,
      dayActivity: {
        idSites: '',
        contentSite: '',
      },
      activityContent: {
        id_supplier: '',
        supplier: '',
        id_location: '',
        location: '',
        id_location_grand: '',
        locationGrand: '',
        activity_theme: 'Activity',
      },
      themeList: [
        { id_activity_theme: 0, activity_theme: 'Food' },
        { id_activity_theme: 1, activity_theme: 'Transportation' },
        { id_activity_theme: 2, activity_theme: 'Activity' },
        { id_activity_theme: 3, activity_theme: 'Service guide' },
        { id_activity_theme: 4, activity_theme: 'Flight' },
        { id_activity_theme: 5, activity_theme: 'Extra' },
        { id_activity_theme: 6, activity_theme: 'Other' },
        { id_activity_theme: 7, activity_theme: 'Multiple' },
        { id_activity_theme: 8, activity_theme: 'Distance' },
      ],
    }
  }


  // Validate
  componentDidMount() {
    const { userId, dayActivity } = this.state
    const {
      history,
      suppliersList,
      GET_SUPPLIERS_LIST,
      tripDetails,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(suppliersList)) {
      GET_SUPPLIERS_LIST(userId)
    }
    if (!_.isEmpty(tripDetails)) {
      this.setState({
        dayActivity: {
          ...dayActivity,
          idSites: tripDetails[0].type_cicuit,
          contentSite: tripDetails[0].centre_interet,
        },

      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      token,
    } = prevState
    const {
      putTripDetailsSite,
      RESET_TRIP_DETAILS_SITE,
      idTripSelected,
      GET_TRIP_DETAILS,
    } = nextProps


    if (putTripDetailsSite && putTripDetailsSite.success) {
      // const { newTrip } = putTripDetails || false

      RESET_TRIP_DETAILS_SITE()
      GET_TRIP_DETAILS(token, idTripSelected)
      return {
        validMsg: 'Changes were successfully recorded !',
      }
    }

    return null
  }

  componentDidUpdate() {
    const {
      putTripDetailsSite,
    } = this.props


    if (putTripDetailsSite && putTripDetailsSite.success) {
      // const { newTrip } = putTripDetails || false
      this.resetValidMsg(2000)
    }
  }


  resetValidMsg = (t = 3000) => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, t)
  }

  handleEditorChange = field => (content) => {
    const { dayActivity } = this.state
    this.setState({
      dayActivity: {
        ...dayActivity,
        [field]: content,
      },
      isValidated: false,
    });
  }

  handleDefaultChange = field => () => {
    const {
      dayActivity,
    } = this.state
    const {
      activityList,
      informationsContent,
    } = this.props
    const tabDayActivity = _.split(dayActivity.idSites, ',')
    const tabDayActivity2 = tabDayActivity.map(tDA => _.toNumber(tDA))
    const dayActivityList = activityList.filter(aL => tabDayActivity2.includes(aL.id_activity))
    const styleCC = {
      border: '1px solid #000000',
      textAlign: 'center',
      fontSize: '12pt',
    }
    const Fonddefaut = {
      backgroundColor: informationsContent.main_color,
      border: '1px solid #000000',
      textAlign: 'center',
      fontSize: '12pt',
    };
    const defaultContent = (
      <div className="mettreRouge">
        <table
          className="tabItineraireSenseGlobal"
          style={{
            borderCollapse: 'collapse',
            borderColor: '#000000',
            borderStyle: 'solid',
            border: 1,
            width: '100%',
          }}
        >
          <tr>
            <th style={Fonddefaut}>Lieu</th>
            <th style={Fonddefaut}>Nom</th>
            <th style={Fonddefaut}>Prix senior</th>
            <th style={Fonddefaut}>Prix adulte</th>
            <th style={Fonddefaut}>Prix enfant</th>
            <th style={Fonddefaut}>Devise</th>
          </tr>
          {dayActivityList.map(dAL => (
            <tr>
              <td style={styleCC}>{dAL.location}</td>
              <td style={styleCC}>{dAL.activity}</td>
              <td style={styleCC}>{dAL.price_seniorpeakseason}</td>
              <td style={styleCC}>{dAL.price_adultpeakseason}</td>
              <td style={styleCC}>{dAL.price_childpeakseason}</td>
              <td style={styleCC}>{dAL.currency}</td>
            </tr>
          ))}
        </table>
      </div>
    )
    console.log(defaultContent)
    const pageHtml = renderToStaticMarkup(defaultContent)
    this.setState({
      dayActivity: {
        ...dayActivity,
        [field]: pageHtml,
      },
      isValidated: false,
    });
  }

  handleActivityDayChange = (content) => {
    const { dayActivity } = this.state
    const tabArrayId = content.map(c => c.id_activity)

    this.setState({
      dayActivity: {
        ...dayActivity,
        idSites: _.join(tabArrayId, ','),
      },
      isValidated: false,
    });
    return true
  }


  displayAutocompleteActivity = (field, list) => {
    const {
      validateError,
      dayActivity,
    } = this.state
    const {
      activityList,
    } = this.props
    const tabDayActivity = _.split(dayActivity.idSites, ',')
    const tabDayActivity2 = tabDayActivity.map(tDA => _.toNumber(tDA))
    const dayActivityList = activityList.filter(aL => tabDayActivity2.includes(aL.id_activity))


    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    return (
      <Autocomplete
        id={`${field}-field`}
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={dayActivityList}
        onChange={(event, newValue) => {
          this.handleActivityDayChange(newValue);
        }}
        options={list}
        getOptionLabel={option => `${option.activity} (${option.universel === 1 ? ('universel') : (`(${option.locationGrand ? `province: ${option.locationGrand}` : 'No province'})(${option.location ? `Location: ${option.location}` : 'No location'})`)})`}
        renderTags={(value, getTagProps) => value.map((option, index) => (
          option.activity_type === 'individual'
            ? (<Chip style={FondDefautIndividual} variant="outlined" label={`${option.activity}` || ''} {...getTagProps({ index })} />)
            : (<Chip style={FondDefautGroup} variant="outlined" label={`${option.activity}` || ''} {...getTagProps({ index })} />)
        ))
        }
        renderInput={params => (
          <Input
            {...params}
            margin="normal"
            placeholder={`Select a ${field}`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }

   // On change location
   onChangeLocation = (event, value) => {
     const { activityContent } = this.state
     // find the global object from this value
     this.setState({
       activityContent: {
         ...activityContent,
         id_location: value ? value.id_lieu : '',
         location: value ? value.location : '',
       },
     })
   }


  filterOptionsLocation = (options, params) => {
    const { locationList } = this.props
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(l => l.lieu === valueClean)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu: `Add "${valueClean}"`,
      });
    }
    return filtered;
  }


  displayAutocompleteLocation = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <Autocomplete
        id={`${field}-field`}
        value={content}
        onChange={this.onChangeLocation}
        filterOptions={this.filterOptionsLocation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelLocation}
        renderOption={option => option.lieu}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }


  onChangeLocationGrand = (event, value) => {
    const { activityContent } = this.state
    // find the global object from this value
    this.setState({
      activityContent: {
        ...activityContent,
        id_location_grand: value ? value.id_lieu_grand : '',
        locationGrand: value ? value.locationGrand : '',
      },
    })
  }

  onChangeSupplier = (event, value) => {
    const { activityContent } = this.state
    // find the global object from this value
    this.setState({
      activityContent: {
        ...activityContent,
        id_supplier: value ? value.id_supplier : '',
        supplier: value ? value.supplier : '',
      },
    })
  }

  getOptionLabelLocation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return ''
    }
    // Regular option
    return option.location;
  }


  getOptionLabelLocationGrand = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.locationGrand) {
      return ''
    }
    // Regular option
    return option.locationGrand;
  }

  displayAutocompleteLocationGrand = (locationGrandList) => {
    const {
      activityContent,
      validateError,
    } = this.state
    return (
      <Autocomplete
        id="locationGrand"
        value={activityContent}
        options={locationGrandList}
        getOptionLabel={this.getOptionLabelLocationGrand}
        onChange={this.onChangeLocationGrand}
        renderInput={params => (
          <TextField
            {...params}
            label="province or region*"
            margin="normal"
            variant="outlined"
            error={validateError ? params.inputProps.value === '' : false}

          />
        )}
      />
    )
  }


  filterOptionsSupplier = (options, params) => {
    const { suppliersList } = this.props
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(suppliersList, d => d.supplier.indexOf(valueClean) > -1)

    // Suggest the creation of a new value

    return filtered;
  }

  getOptionLabelSupplier = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.supplier) {
      return ''
    }
    // Regular option
    return option.supplier;
  }


  displayAutocompleteSupplier = (field, content, list) => {
    const {
      activityContent,
      validateError,
    } = this.state
    return (
      <Autocomplete
        id={`${field}-field`}
        value={activityContent}
        onChange={this.onChangeSupplier}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={this.filterOptionsSupplier}
        options={list}
        getOptionLabel={this.getOptionLabelSupplier}
        renderOption={option => option.supplier}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }

  onChangeActivityTheme = (event, value) => {
    const { activityContent } = this.state
    // find the global object from this value
    this.setState({
      activityContent: {
        ...activityContent,
        activity_theme: value ? value.activity_theme : '',
      },
    })
  }


  getOptionLabelActivityTheme = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.activity_theme) {
      return ''
    }
    // Regular option
    return option.activity_theme;
  }


  displayAutocompleteActivityTheme = (themeList) => {
    const {
      activityContent,
      validateError,
    } = this.state
    return (
      <Autocomplete
        id="activityTheme"
        value={activityContent}
        options={themeList}
        getOptionLabel={this.getOptionLabelActivityTheme}
        onChange={this.onChangeActivityTheme}
        renderInput={params => (
          <TextField
            {...params}
            label="Service theme*"
            margin="normal"
            variant="outlined"
            error={validateError ? params.inputProps.value === '' : false}

          />
        )}
      />
    )
  }


    // return filter
    displayActivityTheme = () => {
      const {
        activityContent,
        themeList,
      } = this.state
      const {
        suppliersList,
      } = this.props
      const { locationList, locationGrandList } = this.props
      return (
        <div>
          <CssGrid
            container
            spacing={2}
          >
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteActivityTheme(themeList)}
            </CssGrid>
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteSupplier('supplier', activityContent, suppliersList)}
            </CssGrid>
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteLocationGrand(locationGrandList)}

            </CssGrid>
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteLocation('location', activityContent, locationList)}
            </CssGrid>
          </CssGrid>
        </div>
      )
    }

    validate = () => {
      const {
        token,
        dayActivity,
      } = this.state
      const {
        PUT_TRIP_DETAILS_SITE,
        idTripSelected,

      } = this.props

      this.setState({
        isValidated: true,
      })
      // Check all required inputs

      PUT_TRIP_DETAILS_SITE(token, idTripSelected, dayActivity)

      return null
    }

    render() {
      const {
        isValidated,
        validMsg,
        activityContent,
        dayActivity,
      } = this.state
      const {
        activityList,
      } = this.props
      const location = activityContent && activityContent.location ? activityContent.location : '';
      const locationGrand = activityContent && activityContent.locationGrand ? activityContent.locationGrand : '';
      const activityTheme = activityContent && activityContent.activity_theme ? activityContent.activity_theme : '';
      const supplierId = activityContent && activityContent.id_supplier ? activityContent.id_supplier : '';
      const selectableActivityList = activityList.filter((supplier) => {
        const locationMatch = (location === 'none' || location === '') || supplier.location === location;
        const regionMatch = (locationGrand === 'none' || locationGrand === '') || supplier.locationGrand === locationGrand;
        const activityThemeMatch = activityTheme === '' || supplier.activity_theme === activityTheme;
        const supplierMatch = supplierId === '' || supplier.id_supplier === supplierId;

        return supplierMatch && activityThemeMatch && locationMatch && regionMatch;
      });
      return (
        <div className="container">
          <Template>
            <div className="requestContainer">
              <Stepper saveData={dest => this.saveData(dest)} />
              <div className="dividerStepper" />
              <div className="extensionContainer">
                <h2>SITES AND VISITS MANAGEMENT</h2>
                {validMsg ? <div className="validMsg">{validMsg}</div> : null}
                { this.displayActivityTheme() }
                {this.displayAutocompleteActivity('day_activity', selectableActivityList)}
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md={6} xs={6}>
                    <h2>Site tab to display</h2>
                    <div className="editorStyle">
                      <p className="editorTitle background-details">Description</p>
                      <TextEditor
                        height={400}
                        object={dayActivity}
                        value={dayActivity.contentSite}
                        handleEditorChange={this.handleEditorChange('contentSite')}
                      />
                      <CssGrid
                        container
                        spacing={1}
                      >
                        <CssGrid item md={4} xs={4}>
                          <div className="buttons-collection">
                            <button
                              type="submit"
                              className="select-localisation-button"
                              onClick={this.handleDefaultChange('contentSite')}
                            >
                              load default site tab
                            </button>
                          </div>
                        </CssGrid>
                      </CssGrid>
                    </div>
                  </CssGrid>
                </CssGrid>
                <CssGrid
                  container
                  spacing={2}
                  className="actionContainer"
                >
                  <CssGrid item md={4} xs={12}>
                    <ActionButton
                      id="save"
                      label="Save"
                      onClick={() => this.validate()}
                    />
                  </CssGrid>
                </CssGrid>
              </div>
            </div>

          </Template>
          <React.Fragment>
            <Prompt
              when={!isValidated}
              message="You have unsaved changes, are you sure you want to leave?"
            />
          </React.Fragment>
        </div>
      )
    }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  locationGrandList: _.filter(state.api.locationGrandList, l => l.id_lieu_grand !== 1),
  suppliersList: _.filter(state.suppliersReducer.suppliersList, l => l.id_supplier !== 1),
  putTripDetailsSite: state.tripSiteReducer.putTripDetailsSite,
  activityList: state.activityReducer.activityList,
  informationsContent: state.informationsReducer.informationsContent,
  idTripSelected: state.api.idTripSelected,

})

const mapDispatchToProps = dispatch => ({
  GET_SUPPLIERS_LIST: userId => dispatch({
    type: 'GET_SUPPLIERS_LIST',
    userId,
  }),
  PUT_TRIP_DETAILS_SITE: (token, idTour, siteDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_SITE',
    token,
    idTour,
    siteDetails,
  }),
  RESET_TRIP_DETAILS_SITE: () => dispatch({
    type: 'RESET_TRIP_DETAILS_SITE',
  }),
  GET_TRIP_DETAILS: (token, idTripSelected) => dispatch({
    type: 'GET_TRIP_DETAILS',
    token,
    idTripSelected,
  }),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TripSite))
