import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import {
  Input,
} from '../../../components'


export default function DisplayLocalisationMap(props) {
  const {
    locationList,
    localisationList,
    handleLocalisationChange,
  } = props

  const [localisationContent, setlocalisationContent] = useState(_.split(localisationList.map_localisation, ','))
  const [OnChangeList, setOnChangeList] = useState(false)


  useEffect(() => {
    const tripLocalitionList = localisationList.map_localisation ? _.split(localisationList.map_localisation, ',') : []
    setlocalisationContent(tripLocalitionList)
  }, [localisationList])

  useEffect(() => {
    if (localisationContent) {
      handleLocalisationChange(localisationContent)
      setOnChangeList(false)
    }
  }, [OnChangeList])


  const displayAutocompleteLocalisation = (field, content, list) => (
    <Autocomplete
      multiple
      id={`${field}-field-${content.id}`}
      options={list.map(option => option.lieu)}
      value={localisationContent}
      onChange={onChangeLocalisation}
      filterOptions={filterOptionsLocalisation(list)}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={getOptionLabelLocalisation}
      renderOption={option => option.lieu}
      freeSolo
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
      ))
        }
      renderInput={params => (
        <Input
          {...params}
          variant="outlined"
          label="Map localisation"
          margin="normal"
          className="largeWidth"
        />
      )}
    />
  )

  const filterOptionsLocalisation = list => (options, params) => {
    const filtered = _.filter(list, d => d.lieu.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    return filtered;
  }

  const getOptionLabelLocalisation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.lieu) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.lieu;
  }

  const onChangeLocalisation = (event, newValue, reason) => {
    // newValue is an array of string representative the rooms selected
    // It contains an object representative the room added
    // if a room is deleted, the input return an array of string without the room deleted
    if (_.isEmpty(newValue)) {
      // room input is empty now
      setlocalisationContent([])
      handleLocalisationChange({})
    } else if (reason === 'remove-option') {
      // a room has been deleted
      setlocalisationContent(newValue)
      handleLocalisationChange(newValue)
    } else {
      setlocalisationContent(
        [
          ...localisationContent,
          newValue[newValue.length - 1].lieu,
        ],
      )
    }
    setOnChangeList(true)
  }


  return (
    <div>
      {displayAutocompleteLocalisation('localisation', localisationList, locationList)}
    </div>

  );
}

DisplayLocalisationMap.propTypes = {
  locationList: PropTypes.array,
  localisationList: PropTypes.object,
  handleLocalisationChange: PropTypes.func.isRequired,


};

DisplayLocalisationMap.defaultProps = {
  locationList: [],
  localisationList: '',
};
